<div class="login-body" [ngClass]="{ 'layout-dark': !dark, 'layout-light': dark }" style="
    background: url('../../assets/layout/images/login_bg/2.1.png');
    background-repeat: no;
    background-size: cover;
    background-position: center;
  ">
  <div class="login-content">
    <div class="login-panel">
      <div class="login-panel-content">
        <div class="logo">
          <img id="login-logo" src="assets/layout/images/logo/labportal.png" alt="mirage-layout" />
        </div>
        <h1>Sign in to LabPortal</h1>
        <p>Welcome. Please Sign in to the LabPortal Network.</p>
        <div class="forms">
          <div class="login-input-wrapper">
            <label for="username">Email</label>
            <input pInputText [(ngModel)]="loginModel.Useremail" placeholder="Email" type="email" name="email"
              id="email" autocomplete="off" />
            <i class="pi pi-envelope"></i>
          </div>

          <div class="login-input-wrapper">
            <label for="password">Password</label>
            <input [(ngModel)]="loginModel.Password" placeholder="Password" type="password" name="password"
              id="password" autocomplete="off" pPassword [feedback]="false" />
            <i class="pi pi-lock"></i>
          </div>

          <div class="col-12">
            <p-checkbox [(ngModel)]="checked" label="Remember me" value="SaveInfo" binary="true"></p-checkbox>
          </div>

          <div class="buttons">
            <button pButton type="submit" (click)="login()" label="Sign In"></button>
            <a style="cursor: pointer" (click)="gotoForgetPassword()" class="secondary-button">Forgot Password?</a>
          </div>
        </div>

        <div class="text-left mt-5">
          No account ?
          <a href="https://app.labportal.vet/#/signup/hospital">Sign up</a>
        </div>

        <!-- <div class="text-left mt-3">
          <a style="cursor: pointer" (click)="goToLink()">Login with Line</a>
        </div> -->
      </div>
    </div>

    <div class="desert"></div>
  </div>
</div>

<div class="loading-overlay" *ngIf="loaderVisible">
  <div class="loader"></div>
</div>