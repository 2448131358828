import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, finalize, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EncrypDepcrypService } from '../_services';
import { AlertifyService } from '../_services/common/alertify.service';

  
  
  @Injectable()
  export class EncryptionInterceptor implements HttpInterceptor {
  
    constructor(
        private _alertifyService: AlertifyService,
        private encryptionService: EncrypDepcrypService, 
    ) {}
  
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if(environment.isCloud && !request.url.includes("Documents/") && !request.url.includes("assets/")) {
        var urls = request.url.split('api/');
        const encryptedUrl = this.encryptionService.encrypt(urls[1]);
        const clonedRequest = request.clone({
          url: `${urls[0]}api/${encryptedUrl}`,
        });
    
        return next.handle(clonedRequest).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 500) {
              this._alertifyService.error('500 Internal Server Error');
            } else if(error.status === 415) {
              this._alertifyService.error("Unsupported Media Type");
            } 
            
            // else {
            //   this._alertifyService.error('An HTTP error occurred');
            // }

            return throwError(() => error);
          }),
          finalize(() => {
            console.log('Finalized');
          })
        );
      } else {
        return next.handle(request).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 500) {
              this._alertifyService.error('500 Internal Server Error');
            } else if(error.status === 415) {
              this._alertifyService.error("Unsupported Media Type");

            } 
            
            // else {
            //   this._alertifyService.error('An HTTP error occurred');
            // }
            return throwError(() => error);
          }),
          finalize(() => {
            console.log('Finalized');
          })
        );
      }
      
    }
  }
  