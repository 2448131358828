import { Binary } from "selenium-webdriver/firefox";
import { Role } from "../role-models/role";
import { HrEmpdocument } from "./HrEmpdocument";

export class User {
  public UserId: number;
  public UserUid: string;
  public UserName: string;
  public IsRadiographer?: boolean;

  public SecurityQuestion: string;
  public SecurityAnswer: string;
  public Pwd: string;
  public UnitId: number;
  public JobtitleId: number;
  public JobType: string;
  public JobTypeId: number;
  public JobTypeLevelId: number;
  public JobTypeName: string;
  public TitleNls: string;
  public FnameNls: string;
  public MnameNls: string;
  public LnameNls: string;
  public TitleEng: string;
  public FnameEng: string;
  public MnameEng: string;
  public LnameEng: string;
  public AliasName: string;
  public Gender: string;
  public EmailPersonal: string;
  public EmailOfficial: string;
  public PhoneHome: string;
  public PhoneMobile: string;
  public PhoneOffice: string;
  public PreferredPhone: string;
  public PabxExt: number;
  public FaxNo: string;
  public Dob: Date;
  public BloodGroup: string;
  public Address: string;
  public DefaultLang: number;
  public Religion: number;
  public AuthLevelId: number;
  public ReportingTo: number;
  public LastPwdModified: Date;
  public CardNo: string;
  public PlaceOfBirth: string;
  public Nationality: string;
  public MStatus: string;
  public IsActive: boolean;
  public SupportUser: boolean;
  public CanKillSession: boolean;
  public AllowOthersToFinalize: boolean;
  public CanExceedSchedule: boolean;
  public ImgFileName: string;
  public EmpReportFooter1: string;
  public EmpReportFooter2: string;
  public Themes: string;
  public MenuLayout: string;
  public ProfileLayout: string;
  public IsCompact: boolean;
  public IsGuest: boolean;
  public OrgId: number;
  public AppCustomerID?: number;
  public CreatedBy: number;
  public CreatedOn: Date;
  public LastModifiedBy: number;
  public LastModifiedOn: Date;
  public PwdHash: Binary;
  public PwdSalt: Binary;
  public TimeZoneId: string;

  public IsAutoRefresh: boolean;
  public RefreshTime: number;

  public OldPwd: string;

  //To verify by Surojit Sarkar
  public OrgName: string;

  /*For email verification and password reset*/
  public EmailActivationCode: string;
  public EmailVerificationExpDatetime: Date;
  public IsEmailVerified: boolean;
  public PwResetExpDatetime: Date;
  public PwResetDatetime: Date;
  public ResetPwCode: string;
  public Host: string;
  public Port: string;
  public Scheme: string;

  //for login model : sayed
  public EmpId: number;
  public Fname: string;
  public Lname: string;
  public FullNameText: string;
  public FullName: string;
  public Status: string;
  public IsSuperAdmin: boolean;
  public WorkListType: string;
  public IsImmediateReportWrite?: boolean;
  public WorklistOrder: string;
  public SignatureFileName: string;
  public SkipEmailVerification: boolean;

  //mahfuz
  public Rolls: Role[] = [];
  public Password: string;
  public AIType: string;
  public OrgType: string;
  public OrgParentId?: number;
  LineAccessToken: string;
  LineUserName: string;
  LineId: string;
  MedicalLincence: string;
  public CountryId?: number;
  public ProvinceId?: number;
  public DistrictId?: number;
  public SubdistrictId?: number;
  public PostalCodeId?: number;
  public ZipCode: string;

  HospitalIds: any[];
  HrEmpsubspecialty: any[];
  hrEmpdocuments: HrEmpdocument[] = [];

  IsPhoneVerified: boolean;
  StudyAssignAllow: boolean;
  StudyUnAssignAllow: boolean;
  StudyDraftAllow: boolean;
  StudyFinalizeAllow: boolean;
  StudyAddendumAllow: boolean;
  StudyResetAllow: boolean;
  StudyResetWindowHour: number;

  StudyAssignAllowOthers?: boolean;
  StudyUnAssignAllowOthers?: boolean;
  StudyResetAllowOthers?: boolean;
  IsImaging?: boolean;
  IsReferring?: boolean;
  IsOwnerMandatory?: boolean;

  Is2faenable?: boolean;
  TotpSecretkey: string;
  TotpEnableon?: Date;
  TotpDisableon?: Date;
  TotpDisablereason: string;
  TotpVerifyCode: string;
  BackupCode: string;
  IsTotpSecretkeyExits: boolean;

  AutoRefreshAllow?: boolean;
  AutoRefreshSecond?: number;
  ItemusesDays?: number;
  ItemstockGoingoutDays?: number;
  OrgImageString: string;
  LandingPage: string;
  LandingPageUrl: string;
}

export class PwdValidatorRes {
  IsPasswordValid!: boolean;
  ValidationMessage: string[];
}

export class PwdValidatorParam {
  Password!: string;
  OrgId?: number;
}
