import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  GeneralResModel,
  InvGoodsIssueDtlModel,
  InvPOViewModel,
  InvQueryViewModel,
  ItemFastMovingModel,
  LedgerItemModel,
  SlideTrackerData,
} from "src/app/_models";
import { CommonResponse } from "src/app/_models/common-response.modle";
import { InvGoodsAdjustmentModel } from "src/app/_models/inventory/inv-goods-adjustment.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class InventoryService {
  private baseURL = `${environment.apiUrl}`;
  private apiPATH = this.baseURL + `Inventory/`;

  constructor(private _httpClient: HttpClient) {}

  getPendingPOForSP(request: InvQueryViewModel) {
    return this._httpClient.post<GeneralResModel<InvPOViewModel[]>>(
      `${this.apiPATH}GetPendingPOForSP`,
      request
    );
  }  
  
  addStockAdjustment(request: InvGoodsAdjustmentModel[]) {
    return this._httpClient.post<GeneralResModel<InvPOViewModel[]>>(
      `${this.apiPATH}AddStockAdjustment`,
      request
    );
  }

  getPOHistoryListForSP(request: InvQueryViewModel) {
    return this._httpClient.post<GeneralResModel<InvPOViewModel[]>>(
      `${this.apiPATH}GetPOHistoryListForSP`,
      request
    );
  }

  getItemFastMovingList(request: InvQueryViewModel) {
    return this._httpClient.post<GeneralResModel<ItemFastMovingModel[]>>(
      `${this.apiPATH}ItemFastMovingList`,
      request
    );
  }

  getItemFastMovingListUsesPercentage(request: InvQueryViewModel) {
    return this._httpClient.post<GeneralResModel<ItemFastMovingModel[]>>(
      `${this.apiPATH}ItemFastMovingListUsesPercentage`,
      request
    );
  }

  getSlideTracker(request: InvQueryViewModel) {
    return this._httpClient.post<GeneralResModel<SlideTrackerData>>(
      `${this.apiPATH}GetSlideTracker`,
      request
    );
  }

  getUsesItem(request: InvQueryViewModel) {
    return this._httpClient.post<GeneralResModel<InvGoodsIssueDtlModel[]>>(
      `${this.apiPATH}GetUsesItem`,
      request
    );
  }

  getUsesOrderHistory(request: InvQueryViewModel) {
    return this._httpClient.post<GeneralResModel<InvGoodsIssueDtlModel[]>>(
      `${this.apiPATH}GetUsesOrderHistory`,
      request
    );
  }

  getPOListByStatus(status: string, orgId: number) {
    return this._httpClient.get<GeneralResModel<InvPOViewModel[]>>(
      `${this.apiPATH}GetPOListByStatus/${status}/${orgId}`
    );
  }

  getPOHistoryList(request: InvQueryViewModel) {
    return this._httpClient.post<GeneralResModel<InvPOViewModel[]>>(
      `${this.apiPATH}GetPOHistoryList`,
      request
    );
  }

  getJasperReportFile(DynamicParams: string) {
    return this._httpClient.get<any>(
      `${this.apiPATH}getJasperReportFile?${DynamicParams}`
    );
  }

  generatePO(itemId: number) {
    return this._httpClient.get<GeneralResModel<InvPOViewModel>>(
      `${this.apiPATH}GeneratePO/${itemId}`
    );
  }

  upadatedPO(request: InvPOViewModel) {
    return this._httpClient.post<GeneralResModel<InvPOViewModel>>(
      `${this.apiPATH}UpadatedPO`,
      request
    );
  }

  upadatedPOList(request: InvPOViewModel[]) {
    return this._httpClient.post<GeneralResModel<InvPOViewModel[]>>(
      `${this.apiPATH}UpadatedPOList`,
      request
    );
  }

  goodsReceived(request: InvPOViewModel) {
    return this._httpClient.post<GeneralResModel<InvPOViewModel>>(
      `${this.apiPATH}GoodsReceived`,
      request
    );
  }

  getReceiveHistoryList(request: InvQueryViewModel) {
    return this._httpClient.post<GeneralResModel<InvPOViewModel[]>>(
      `${this.apiPATH}GetReceiveHistoryList`,
      request
    );
  }

  goodsReceivedList(request: InvPOViewModel[]) {
    return this._httpClient.post<GeneralResModel<InvPOViewModel[]>>(
      `${this.apiPATH}GoodsReceivedList`,
      request
    );
  }

  getReceiveDetails(transId: number) {
    return this._httpClient.get<GeneralResModel<InvPOViewModel>>(
      `${this.apiPATH}GetReceiveDetails/${transId}`
    );
  }

  getItemLedger(request: InvQueryViewModel) {
    return this._httpClient.post<GeneralResModel<LedgerItemModel[]>>(
      `${this.apiPATH}GetItemLedger`,
      request
    );
  }

  getAdjustmentList(orgId: number) {
    return this._httpClient.get<CommonResponse>(
      `${this.apiPATH}GetAdjustmentList/${orgId}`
    );
  }
  getAdjustmentListById(orgId: number,adjustmentId?:number,adjustmentdtlId?:number) {
    return this._httpClient.get<CommonResponse>(
      `${this.apiPATH}GetAdjustmentListById/${orgId}/${adjustmentId}/${adjustmentdtlId}`
    );
  }
}
