<p-steps
  [model]="items"
  [(activeIndex)]="activeIndex"
  [readonly]="true"
></p-steps>

<div *ngIf="activeIndex == 0">
  <app-radiologist-signup
    [LineId]="lineId"
    (userEmitter)="reciveUserData($event)"
  ></app-radiologist-signup>
</div>

<div *ngIf="activeIndex == 1">
  <app-radiologist-phone-verification></app-radiologist-phone-verification>
</div>

<div *ngIf="activeIndex == 2">
  <app-radiologist-email-verification></app-radiologist-email-verification>
</div>

<div>
  <p-checkbox name="groupname" value="val1"></p-checkbox> I have read and agree
  to the <a> VetPortal Account Terms of Use </a> <br />

  <p-checkbox name="groupname" value="val1"></p-checkbox> I have read and agree
  to the <a> VetPortal Account API Terms of Use </a>
</div>

<div class="pull-right">
  <button
    *ngIf="activeIndex >= 1"
    pButton
    (click)="PrevMenu()"
    label="Prev"
  ></button>

  <button
    *ngIf="activeIndex <= 1"
    pButton
    (click)="NextMenu()"
    label="Next"
  ></button>
  <button *ngIf="activeIndex == 2" pButton label="Finish"></button>
</div>
