import {
  DatePipe,
  HashLocationStrategy,
  LocationStrategy,
} from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RadiologistPhoneVerificationComponent } from "./_modules/sign-up/radiologist/radiologist-phone-verification/radiologist-phone-verification.component";
import { LoginWithLineComponent } from "./login-with-line/login-with-line.component";

import { PreloadAllModules, RouterModule } from "@angular/router";
import { JwtHelperService, JwtModule } from "@auth0/angular-jwt";
import * as loginReducer from "./_store/reducers/login.reducer";
import { routes } from "./app.routes";

// PrimeNG Components for demos
import { FullCalendarModule } from "@fullcalendar/angular";
import { AccordionModule } from "primeng/accordion";
import { AutoCompleteModule } from "primeng/autocomplete";
import { AvatarModule } from "primeng/avatar";
import { AvatarGroupModule } from "primeng/avatargroup";
import { BadgeModule } from "primeng/badge";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CardModule } from "primeng/card";
import { CarouselModule } from "primeng/carousel";
import { CascadeSelectModule } from "primeng/cascadeselect";
import { ChartModule } from "primeng/chart";
import { CheckboxModule } from "primeng/checkbox";
import { ChipModule } from "primeng/chip";
import { ChipsModule } from "primeng/chips";
import { CodeHighlighterModule } from "primeng/codehighlighter";
import { ColorPickerModule } from "primeng/colorpicker";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { ContextMenuModule } from "primeng/contextmenu";
import { DataViewModule } from "primeng/dataview";
import { DialogModule } from "primeng/dialog";
import { DividerModule } from "primeng/divider";
import { DropdownModule } from "primeng/dropdown";
import { FieldsetModule } from "primeng/fieldset";
import { FileUploadModule } from "primeng/fileupload";
import { GalleriaModule } from "primeng/galleria";
import { ImageModule } from "primeng/image";
import { InplaceModule } from "primeng/inplace";
import { InputMaskModule } from "primeng/inputmask";
import { InputNumberModule } from "primeng/inputnumber";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { KnobModule } from "primeng/knob";
import { LightboxModule } from "primeng/lightbox";
import { ListboxModule } from "primeng/listbox";
import { MegaMenuModule } from "primeng/megamenu";
import { MenuModule } from "primeng/menu";
import { MenubarModule } from "primeng/menubar";
import { MessageModule } from "primeng/message";
import { MessagesModule } from "primeng/messages";
import { MultiSelectModule } from "primeng/multiselect";
import { OrderListModule } from "primeng/orderlist";
import { OrganizationChartModule } from "primeng/organizationchart";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { PaginatorModule } from "primeng/paginator";
import { PanelModule } from "primeng/panel";
import { PanelMenuModule } from "primeng/panelmenu";
import { PasswordModule } from "primeng/password";
import { PickListModule } from "primeng/picklist";
import { ProgressBarModule } from "primeng/progressbar";
import { RadioButtonModule } from "primeng/radiobutton";
import { RatingModule } from "primeng/rating";
import { RippleModule } from "primeng/ripple";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { ScrollTopModule } from "primeng/scrolltop";
import { SelectButtonModule } from "primeng/selectbutton";
import { SidebarModule } from "primeng/sidebar";
import { SkeletonModule } from "primeng/skeleton";
import { SlideMenuModule } from "primeng/slidemenu";
import { SliderModule } from "primeng/slider";
import { SplitButtonModule } from "primeng/splitbutton";
import { SplitterModule } from "primeng/splitter";
import { StepsModule } from "primeng/steps";
import { TableModule } from "primeng/table";
import { TabMenuModule } from "primeng/tabmenu";
import { TabViewModule } from "primeng/tabview";
import { TagModule } from "primeng/tag";
import { TerminalModule } from "primeng/terminal";
import { TieredMenuModule } from "primeng/tieredmenu";
import { TimelineModule } from "primeng/timeline";
import { ToastModule } from "primeng/toast";
import { ToggleButtonModule } from "primeng/togglebutton";
import { ToolbarModule } from "primeng/toolbar";
import { TooltipModule } from "primeng/tooltip";
import { TreeModule } from "primeng/tree";
import { TreeTableModule } from "primeng/treetable";
import { VirtualScrollerModule } from "primeng/virtualscroller";

// Application Components
import { AppBreadcrumbComponent } from "./app.breadcrumb.component";
import { AppComponent } from "./app.component";
import { AppConfigComponent } from "./app.config.component";
import { AppFooterComponent } from "./app.footer.component";
import { AppMainComponent } from "./app.main.component";
import { AppMenuComponent } from "./app.menu.component";
import { AppMenuitemComponent } from "./app.menuitem.component";
import { AppRightPanelComponent } from "./app.rightpanel.component";
import { AppRoutingModule } from "./app.routes";
import { AppTopBarComponent } from "./app.topbar.component";
import { AppAccessdeniedComponent } from "./pages/app.accessdenied.component";
import { AppCalendarComponent } from "./pages/app.calendar.component";
import { AppCrudComponent } from "./pages/app.crud.component";
import { AppErrorComponent } from "./pages/app.error.component";
import { AppLoginComponent } from "./pages/app.login.component";
import { AppNotfoundComponent } from "./pages/app.notfound.component";
import { AppTimelineDemoComponent } from "./pages/app.timelinedemo.component";

// Demo pages
import { BlocksComponent } from "./blocks/blocks/blocks.component";
import { BlockViewer } from "./blocks/blockviewer/blockviewer.component";
import { ButtonDemoComponent } from "./demo/view/buttondemo.component";
import { ChartsDemoComponent } from "./demo/view/chartsdemo.component";
import { DashboardDemoComponent } from "./demo/view/dashboarddemo.component";
import { DocumentationComponent } from "./demo/view/documentation.component";
import { EmptyDemoComponent } from "./demo/view/emptydemo.component";
import { FileDemoComponent } from "./demo/view/filedemo.component";
import { FloatLabelDemoComponent } from "./demo/view/floatlabeldemo.component";
import { FormLayoutDemoComponent } from "./demo/view/formlayoutdemo.component";
import { InputDemoComponent } from "./demo/view/inputdemo.component";
import { InvalidStateDemoComponent } from "./demo/view/invalidstatedemo.component";
import { ListDemoComponent } from "./demo/view/listdemo.component";
import { MediaDemoComponent } from "./demo/view/mediademo.component";
import { MenusComponent } from "./demo/view/menus/menus.component";
import { MessagesDemoComponent } from "./demo/view/messagesdemo.component";
import { MiscDemoComponent } from "./demo/view/miscdemo.component";
import { OverlaysDemoComponent } from "./demo/view/overlaysdemo.component";
import { PanelsDemoComponent } from "./demo/view/panelsdemo.component";
import { TableDemoComponent } from "./demo/view/tabledemo.component";
import { TreeDemoComponent } from "./demo/view/treedemo.component";
import { IconsComponent } from "./utilities/icons.component";

// Demo services
import { ConfigService } from "./demo/service/app.config.service";
import { CountryService } from "./demo/service/countryservice";
import { CustomerService } from "./demo/service/customerservice";
import { EventService } from "./demo/service/eventservice";
import { IconService } from "./demo/service/iconservice";
import { NodeService } from "./demo/service/nodeservice";
import { PhotoService } from "./demo/service/photoservice";
import { ProductService } from "./demo/service/productservice";

// Application services
import { MenuService } from "./app.menu.service";
import { AppCodeModule } from "./blocks/app-code/app.code.component";
import { BreadcrumbService } from "./breadcrumb.service";

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { StoreModule } from "@ngrx/store";
import { ConfirmationService } from "primeng/api";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { DashboardComponent } from "./_modules/dashboard/dashboard.component";
import { HospitalSignupComponent } from "./_modules/sign-up/hospital/hospital-signup/hospital-signup.component";
import { RadiologistEmailVerificationComponent } from "./_modules/sign-up/radiologist/radiologist-email-verification/radiologist-email-verification.component";
import { RadiologistSignupComponent } from "./_modules/sign-up/radiologist/radiologist-signup/radiologist-signup.component";
import { ApiService } from "./_services/api/api.service";
import { CloudApiService } from "./_services/api/cloudApi.service";
import { AlertifyService } from "./_services/common/alertify.service";
import { AppSessionState } from "./_services/common/app.service";
import { StudyWorklistService } from "./_services/worklist/study-worklist.service";
import { LoginComponent } from "./login/login.component";

import { NgOtpInputModule } from "ng-otp-input";
import { KeyFilterModule } from "primeng/keyfilter";
import { EncryptionInterceptor } from "./_interceptor/EncryptionInterceptor";
import { TokenInterceptor } from "./_interceptor/TokenInterceptor";
import { RadiologistResetPasswordComponent } from "./_modules/sign-up/radiologist/radiologist-reset-password/radiologist-reset-password.component";
import { RadiologistSignupStepsComponent } from "./_modules/sign-up/radiologist/radiologist-signup-steps/radiologist-signup-steps.component";
import { RadiologistSignupTwoComponent } from "./_modules/sign-up/radiologist/radiologist-signup-two/radiologist-signup-two.component";
import { AuthService } from "./_services/auth/auth.service";
import { UserVerifyService } from "./_services/user/user-verify.service";
import { TwoFactorAuthVerifyComponent } from "./two-factor-auth-verify/two-factor-auth-verify.component";

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin,
]);

const APP_PROVIDERS = [
  AlertifyService,
  AppSessionState,
  JwtHelperService,
  ConfirmationService,
  DatePipe,
];
export function getAccessToken() {
  return localStorage.getItem("token");
}

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AppCodeModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AccordionModule,
    AutoCompleteModule,
    AvatarModule,
    AvatarGroupModule,
    BadgeModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    CascadeSelectModule,
    ChartModule,
    CheckboxModule,
    ChipModule,
    ChipsModule,
    CodeHighlighterModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    ColorPickerModule,
    ContextMenuModule,
    DataViewModule,
    DialogModule,
    DividerModule,
    DropdownModule,
    FieldsetModule,
    FileUploadModule,
    FullCalendarModule,
    GalleriaModule,
    ImageModule,
    InplaceModule,
    InputNumberModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    KnobModule,
    LightboxModule,
    ListboxModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OrderListModule,
    OrganizationChartModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    PickListModule,
    ProgressBarModule,
    RadioButtonModule,
    RatingModule,
    RippleModule,
    ScrollPanelModule,
    ScrollTopModule,
    SelectButtonModule,
    SidebarModule,
    SkeletonModule,
    SlideMenuModule,
    SliderModule,
    SplitButtonModule,
    SplitterModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    TagModule,
    TerminalModule,
    TimelineModule,
    TieredMenuModule,
    ToastModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    TreeTableModule,
    VirtualScrollerModule,
    ProgressSpinnerModule,
    NgOtpInputModule,
    KeyFilterModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: getAccessToken,
        allowedDomains: ["localhost:44376", "localhost:8089"],
      },
    }),
    StoreModule.forRoot({ loginStore: loginReducer.reducer }),
  ],
  declarations: [
    AppComponent,
    AppMainComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    AppTopBarComponent,
    AppFooterComponent,
    AppRightPanelComponent,
    AppConfigComponent,
    AppBreadcrumbComponent,
    AppNotfoundComponent,
    AppErrorComponent,
    AppAccessdeniedComponent,
    AppLoginComponent,
    AppCrudComponent,
    AppCalendarComponent,
    AppTimelineDemoComponent,
    DashboardDemoComponent,
    FormLayoutDemoComponent,
    FloatLabelDemoComponent,
    InvalidStateDemoComponent,
    InputDemoComponent,
    ButtonDemoComponent,
    TableDemoComponent,
    ListDemoComponent,
    TreeDemoComponent,
    PanelsDemoComponent,
    OverlaysDemoComponent,
    MediaDemoComponent,
    MenusComponent,
    MessagesDemoComponent,
    MessagesDemoComponent,
    MiscDemoComponent,
    ChartsDemoComponent,
    EmptyDemoComponent,
    FileDemoComponent,
    DocumentationComponent,
    IconsComponent,
    BlocksComponent,
    BlockViewer,
    LoginComponent,
    RadiologistSignupComponent,
    HospitalSignupComponent,
    LoginWithLineComponent,
    DashboardComponent,
    RadiologistPhoneVerificationComponent,
    RadiologistEmailVerificationComponent,
    RadiologistSignupStepsComponent,
    RadiologistSignupTwoComponent,
    RadiologistResetPasswordComponent,
    TwoFactorAuthVerifyComponent,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    CountryService,
    CustomerService,
    EventService,
    IconService,
    NodeService,
    PhotoService,
    ProductService,
    MenuService,
    BreadcrumbService,
    ConfigService,
    APP_PROVIDERS,
    ApiService,
    CloudApiService,
    StudyWorklistService,
    UserVerifyService,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncryptionInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
