import { InjectionToken, NgModule } from "@angular/core";
import { ActivatedRouteSnapshot, RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./login/login.component";

import { DashboardDemoComponent } from "./demo/view/dashboarddemo.component";

import { HospitalSignupComponent } from "./_modules/sign-up/hospital/hospital-signup/hospital-signup.component";
import { RadiologistResetPasswordComponent } from "./_modules/sign-up/radiologist/radiologist-reset-password/radiologist-reset-password.component";
import { RadiologistSignupTwoComponent } from "./_modules/sign-up/radiologist/radiologist-signup-two/radiologist-signup-two.component";
import { LoginWithLineComponent } from "./login-with-line/login-with-line.component";
import { TwoFactorAuthVerifyComponent } from "./two-factor-auth-verify/two-factor-auth-verify.component";

export const routes: Routes = [
  { path: "", component: LoginComponent },
  // { path: "signup/radiologist", component: RadiologistSignupTwoComponent },
  {
    path: "signup/radiologist/:LineId",
    component: RadiologistSignupTwoComponent,
  },
  { path: "signup/hospital", component: HospitalSignupComponent },
  { path: "OAuth", component: LoginWithLineComponent },
  { path: "signup/hospital", component: HospitalSignupComponent },
  {
    path: "reset-password/radiologist",
    component: RadiologistResetPasswordComponent,
  },
  { path: "two-factor-auth-verify", component: TwoFactorAuthVerifyComponent },
  { path: "demo-dashboard", component: DashboardDemoComponent },
  {
    path: "study",
    loadChildren: () =>
      import("./_modules/study/study.module").then((mod) => mod.StudyModule),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./_modules/dashboard/dashboard.module").then(
        (mod) => mod.DashboardModule
      ),
  },
  { path: "**", component: LoginComponent },
];

@NgModule({
  providers: [
    {
      provide: new InjectionToken("externalUrlRedirectResolver"),
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = route.paramMap.get("externalUrl");
        window.open(externalUrl, "_self");
      },
    },
  ],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

// ----Old
// @NgModule({
//     imports: [
//         RouterModule.forRoot([
//             {
//                 path: '', component: LoginComponent,
//                 // path: '', component: AppMainComponent,
//                 // children: [
//                 //     {path: '', component: DashboardDemoComponent},
//                 //     {path: 'uikit/formlayout', component: FormLayoutDemoComponent},
//                 //     {path: 'uikit/floatlabel', component: FloatLabelDemoComponent},
//                 //     {path: 'uikit/invalidstate', component: InvalidStateDemoComponent},
//                 //     {path: 'uikit/input', component: InputDemoComponent},
//                 //     {path: 'uikit/button', component: ButtonDemoComponent},
//                 //     {path: 'uikit/table', component: TableDemoComponent},
//                 //     {path: 'uikit/list', component: ListDemoComponent},
//                 //     {path: 'uikit/tree', component: TreeDemoComponent},
//                 //     {path: 'uikit/panel', component: PanelsDemoComponent},
//                 //     {path: 'uikit/overlay', component: OverlaysDemoComponent},
//                 //     {path: 'uikit/media', component: MediaDemoComponent},
//                 //     {path: 'uikit/menu', loadChildren: () => import('./demo/view/menus/menus.module').then(m => m.MenusModule)},
//                 //     {path: 'uikit/message', component: MessagesDemoComponent},
//                 //     {path: 'uikit/misc', component: MiscDemoComponent},
//                 //     {path: 'uikit/charts', component: ChartsDemoComponent},
//                 //     {path: 'uikit/file', component: FileDemoComponent},
//                 //     {path: 'utilities/icons', component: IconsComponent},
//                 //     {path: 'pages/empty', component: EmptyDemoComponent},
//                 //     {path: 'pages/crud', component: AppCrudComponent},
//                 //     {path: 'pages/calendar', component: AppCalendarComponent},
//                 //     {path: 'pages/timeline', component: AppTimelineDemoComponent},
//                 //     {path: 'components/charts', component: ChartsDemoComponent},
//                 //     {path: 'components/file', component: FileDemoComponent},
//                 //     {path: 'documentation', component: DocumentationComponent},
//                 //     {path: 'blocks', component: BlocksComponent},
//                 // ]
//             },
//             {path: 'error', component: AppErrorComponent},
//             {path: 'accessdenied', component: AppAccessdeniedComponent},
//             {path: 'notfound', component: AppNotfoundComponent},
//             {path: 'login', component: AppLoginComponent},
//             {path: '**', redirectTo: '/notfound'},
//         ], {scrollPositionRestoration: 'enabled'})
//     ],
//     exports: [RouterModule]
// })
// export class AppRoutingModule {
// }
