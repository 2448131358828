import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, map } from "rxjs";
import {
  ChangeOrderStatusReqModel,
  DocumentHistoryResModel,
  ExamResultNote,
  GeneralResModel,
  OrderDocumentModel,
  OrderStudyDeclineReqModel,
  OrgResponseViewModel,
  OwnerWiseStudyResultHistoryModel,
  PatientWiseStudyHistoryModel,
  RatingViewModel,
  RisExamResultKeyImagesModel,
  RisOrderModel,
  ServiceTypeModel,
  StudyHistoryQuery,
  StudyOwnerHistoryQuery,
  StudyTrackingModel,
} from "src/app/_models";
import { Assignment } from "src/app/_models/assignments/assignment.model";
import { CommonResponse } from "src/app/_models/common-response.modle";
import { DicomParams } from "src/app/_models/dicom/dicom-params.model";
import { ExamResult } from "src/app/_models/exam/exam-result.model";
import { ShareLink } from "src/app/_models/share-link.model";
import { SiteInformation } from "src/app/_models/site-information.model";
import { WorklistQueryModel } from "src/app/_models/worklist-query-model";
import { environment } from "src/environments/environment";
import { StudyModel } from "../../_models/study.model";
import { ApiService } from "../api/api.service";
import { CloudApiService } from "../api/cloudApi.service";

@Injectable({
  providedIn: "root",
})
export class StudyWorklistService {
  private rootURL = `${environment.apiUrl}`;
  private baseURL = `${environment.apiUrl}PatientOrder/`;
  private apiPATH = "PatientOrder/";
  private examResultApiPATH = `${environment.apiUrl}ExamResult/`;

  constructor(
    private api: ApiService,
    private cloudApi: CloudApiService,
    private _httpClient: HttpClient,
    private _datePipe: DatePipe
  ) {}

  private clickEvent = new BehaviorSubject<boolean>(false);
  checkClickEvent$ = this.clickEvent.asObservable();

  changeClickEvent(check: boolean) {
    this.clickEvent.next(check);
  }

  private clicksaveEvent = new BehaviorSubject<boolean>(false);
  clicksaveEvent$ = this.clicksaveEvent.asObservable();

  changeSaveClickEvent(check: boolean) {
    this.clicksaveEvent.next(check);
  }

  deleteAnalyzerOrder(orderId: number, analyzerId: number) {
    return this._httpClient.delete<GeneralResModel<boolean>>(
      `${this.baseURL}DeleteAnalyzerOrder/${orderId}/${analyzerId}`
    );
  }

  manualOrderCreate(request: StudyModel) {
    let response = this.api.post<GeneralResModel<RisOrderModel>>(
      `${this.apiPATH}ManualOrderCreate`,
      request
    );
    return response;
  }

  getOwnerPatientStudyHistory(query: StudyOwnerHistoryQuery) {
    let response = this.api.post<GeneralResModel<OwnerWiseStudyResultHistoryModel>>(
      `${this.apiPATH}GetOwnerPatientStudyHistory`,
      query
    );
    return response;
  }

  getPatientStudyHistory(query: StudyHistoryQuery) {
    let response = this.api.post<GeneralResModel<PatientWiseStudyHistoryModel>>(
      `${this.apiPATH}GetPatientStudyHistory`,
      query
    );
    return response;
  }

  getPatientValidaty(orderId: number, accNo: string, userId: number) {
    return this._httpClient.get<GeneralResModel<OrgResponseViewModel>>(
      `${this.baseURL}GetPatientValidaty/${orderId}/${accNo}/${userId}`
    );
  }

  getAllTATByOrgId(orgParentId: number) {
    return this.api.get<CommonResponse>(
      `${this.apiPATH}getTATList/${orgParentId}`
    );
  }

  getPatientInfo(regId: number) {
    return this.api.get<CommonResponse>(
      `${this.apiPATH}GetPatientInfoByRegId/${regId}`
    );
  }

  getPatientInformationByAccessionNo(accessionNo: string) {
    let info = this.api.get<StudyModel>(
      `${this.apiPATH}patientInfo/${accessionNo}`
    );
    return info;
  }

  assignStudyMatrix(rowData: Assignment) {
    let response = this.api.post<boolean>(
      `${this.apiPATH}AddStudyAssign`,
      rowData
    );
    return response;
  }

  createShareLinkByStudy(rowData: StudyModel) {
    let response = this.api.post<ShareLink>(
      `${this.apiPATH}createShareLinkByStudy`,
      rowData
    );
    return response;
  }

  updatePrintCount(acno: string) {
    console.log("url" + `${this.apiPATH}/updatePrintCount/${acno}`);
    return this.api.get<boolean>(`${this.apiPATH}updatePrintCount/${acno}`);
  }

  resendToPacs(StudyInstanceUId: string) {
    let response = this.api.post<boolean>(
      `${this.apiPATH}resendToLocalPacs/${StudyInstanceUId}`,
      null
    );
    return response;
  }

  resendToRis(StudyInstanceUId: string) {
    let response = this.api.post<boolean>(
      `${this.apiPATH}resendToRis/${StudyInstanceUId}`,
      null
    );
    return response;
  }

  resendToPACS(studyModel: StudyModel) {
    let response = this.api.post<boolean>(
      `${this.apiPATH}resendToPACS`,
      studyModel
    );
    return response;
  }

  getOrderInformation(orderId: number, orgId: number) {
    return this._httpClient.get<GeneralResModel<StudyModel[]>>(
      `${this.baseURL}GetOrderInformation/${orderId}/${orgId}`
    );
  }

  getRisSiteOrdersByStatus(queryModel: WorklistQueryModel) {
    let worklist = this.api
      .post<StudyModel[]>(`${this.apiPATH}GetRisSiteOrdersByStatus`, queryModel)
      .pipe(
        map((items) => {
          items.map((i) => {
            i.ExamDtText = this._datePipe.transform(
              i.ExamDt,
              "dd-MM-yyyy, hh:mm:a"
            );
            i.OrderDtText = this._datePipe.transform(
              i.OrderDt,
              "dd-MM-yyyy, hh:mm:a"
            );
            i.LastModifiedOnText = this._datePipe.transform(
              i.LastModifiedOn,
              "dd-MM-yyyy, hh:mm:a"
            );
          });
          return items;
        })
      );
    return worklist;
  }

  getStudyWorklistByDateRange(
    fromDate: string,
    toDate: string,
    orgId: number,
    isCloud: boolean
  ) {
    let worklist = this.api.get<StudyModel[]>(
      `${this.apiPATH}patientOrders/${fromDate}/${toDate}/${orgId}/${isCloud}`
    );

    return worklist;
  }

  getStudyWorklistBySiteList(queryModel: WorklistQueryModel) {
    let worklist = this.api
      .post<StudyModel[]>(`${this.apiPATH}patientSiteOrders`, queryModel)
      .pipe(
        map((items) => {
          items.map((i) => {
            i.ExamDtText = this._datePipe.transform(
              i.ExamDt,
              "dd-MM-yyyy,  hh:mm a"
            );
            i.OrderDtText = this._datePipe.transform(
              i.OrderDt,
              "dd-MM-yyyy, hh:mm a"
            );
            i.LastModifiedOnText = this._datePipe.transform(
              i.LastModifiedOn,
              "dd-MM-yyyy, hh:mm a"
            );
          });
          return items;
        })
      );
    return worklist;
  }

  getStudyWorklistBySiteListByUser(queryModel: WorklistQueryModel) {
    let worklist = this.api.post<StudyModel[]>(
      `${this.apiPATH}GetPatientSiteOrdersByUser`,
      queryModel
    );
    return worklist;
  }

  getAccessionNumberBySuffixAndPrefix(prefix: string, suffix: string) {
    let response = this.api.get<StudyModel[]>(
      `${this.apiPATH}accessionByPrefixSuffix/${prefix}/${suffix}`
    );
    return response;
  }

  updateOrderStatus(studyModel: StudyModel) {
    //debugger;
    let response = this.api.put<boolean>(
      `${this.apiPATH}updateOrderStatus`,
      studyModel
    );
    return response;
  }

  resendLocalStudy(studyModel: StudyModel) {
    //debugger;
    let response = this.api.put<DicomParams>(
      `${this.apiPATH}resendLocalStudy`,
      studyModel
    );
    return response;
  }

  resendCloudStudy(dicomStudy: DicomParams) {
    try {
      let response = this.cloudApi.put<boolean>(
        `${this.apiPATH}resendCloudStudy`,
        dicomStudy
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  updateLocalStudyForReconcilation(studyModel: StudyModel) {
    //debugger;
    let response = this.api.put<StudyModel>(
      `${this.apiPATH}updateLocalStudyForReconcilation`,
      studyModel
    );
    return response;
  }

  updateandSubmitStudy(studyModel: StudyModel) {
    let response = this.api.put<boolean>(
      `${this.apiPATH}UpdateandSubmitStudy`,
      studyModel
    );
    return response;
  }

  addStudyExam(studyModel: StudyModel) {
    let response = this.api.put<CommonResponse>(
      `${this.apiPATH}AddStudyExam`,
      studyModel
    );
    return response;
  }

  updateCloudStudyForReconcilation(studyModel: StudyModel) {
    //debugger;
    let response = this.cloudApi.put<boolean>(
      `${this.apiPATH}updateCloudStudyForReconcilation`,
      studyModel
    );
    return response;
  }

  cancelStudy(studyModel: StudyModel) {
    try {
      let response = this.api.put<boolean>(
        `${this.apiPATH}cancelStudyWorkList`,
        studyModel
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  cancelCloudStudy(studyModel: StudyModel) {
    try {
      let response = this.cloudApi.put<boolean>(
        `${this.apiPATH}cancelStudyWorkList`,
        studyModel
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  getExamResult(orderId: number, orgId: number) {
    let response = this.api.get<string>(
      `${this.apiPATH}getExamResult/${orderId}/${orgId}`
    );
    return response;
  }

  getOrderDate(accNo: string, orgId: number) {
    let response = this.api.get<string>(
      `${this.apiPATH}getOrderDate/${orgId}/${accNo}`
    );
    return response;
  }

  uploadOrderDocument(request: FormData) {
    return this._httpClient.post<OrderDocumentModel[]>(
      `${this.baseURL}uploadOrderDocument`,
      request,
      {
        responseType: "json",
      }
    );
  }

  uploadStudyDicom(request: FormData) {
    return this._httpClient.post<OrderDocumentModel[]>(
      `${this.baseURL}uploadStudyDicom`,
      request,
      {
        responseType: "json",
      }
    );
  }

  getUploadedDocumentList(orderId: number) {
    return this._httpClient.get<OrderDocumentModel[]>(
      `${this.baseURL}getUploadedDocumentList/${orderId}`
    );
  }

  downloadOrderDocument(orderDocumentId: number) {
    return this._httpClient.post(
      `${this.baseURL}downloadOrderDocument/${orderDocumentId}`,
      {}
    );
  }

  deleteOrderDocument(orderDocumentId: number) {
    return this._httpClient.post<boolean>(
      `${this.baseURL}deleteOrderDocument/${orderDocumentId}`,
      {}
    );
  }

  checkDocumentHistory(orderId: number) {
    return this._httpClient.get<GeneralResModel<DocumentHistoryResModel>>(
      `${this.baseURL}CheckDocumentHistory/${orderId}`
    );
  }

  confirmStudySubmit(request: StudyModel) {
    return this._httpClient.put<boolean>(
      `${this.baseURL}updateOrderStatus`,
      request
    );
  }

  updateRating(request: RatingViewModel) {
    return this._httpClient.post<GeneralResModel<string>>(
      `${this.baseURL}UpdateRating`,
      request
    );
  }

  examResultUpdateRatingClear(request: RatingViewModel) {
    return this._httpClient.post<GeneralResModel<string>>(
      `${this.baseURL}ExamResultUpdateRatingClear`,
      request
    );
  }

  getCustomerByOrgParentId(orgId: number) {
    return this._httpClient.get<SiteInformation[]>(
      `${this.rootURL}Site/getCustomerByOrgParentId/${orgId}`
    );
  }

  getHospitalByOrgParentIdList(userList: SiteInformation[]) {
    return this._httpClient.post<SiteInformation[]>(
      `${this.rootURL}Site/getHospitalByOrgParentIdList`,
      userList
    );
  }

  declineOrderStudy(request: OrderStudyDeclineReqModel) {
    return this._httpClient.post<boolean>(
      `${this.baseURL}StudyDecline`,
      request
    );
  }

  saveExamResultNote(request: ExamResultNote) {
    return this._httpClient.post<ExamResultNote[]>(
      `${this.rootURL}ExamResult/saveExamResultNote`,
      request
    );
  }

  getExamResultNote(orderId: number) {
    return this._httpClient.get<ExamResultNote[]>(
      `${this.rootURL}ExamResult/getExamResultNote/${orderId}`
    );
  }

  getAllServiceTypeList(orgId: number) {
    return this._httpClient.get<ServiceTypeModel[]>(
      `${this.rootURL}ExamResult/getAllServiceTypeList/${orgId}`
    );
  }

  getStudyTracking(orderId: number, accessionNo: string) {
    return this._httpClient.get<GeneralResModel<StudyTrackingModel[]>>(
      `${this.baseURL}getStudyWorkflow/${orderId}/${accessionNo}`
    );
  }

  getStudyTrackingByAccessionNo(accessionNo: string) {
    return this._httpClient.get<GeneralResModel<StudyTrackingModel[]>>(
      `${this.baseURL}getStudyTrackingByAccessionNo/${accessionNo}`
    );
  }

  getStudyOrderByAccessionNo(accessionNo: string) {
    return this._httpClient.get<GeneralResModel<StudyModel>>(
      `${this.baseURL}getStudyOrderByAccessionNo/${accessionNo}`
    );
  }

  GetJasperReportFile(DynamicParams: string) {
    return this.api.get<any>(
      `${this.apiPATH}GetJasperReportFile?${DynamicParams}`
    );
  }

  downloadDicomFile(orderID: number, accessionNo: string) {
    return this._httpClient.post(
      `${this.baseURL}downloadDicomFile/${orderID}/${accessionNo}`,
      {}
    );
  }

  changeStudyStatus(request: ChangeOrderStatusReqModel) {
    return this._httpClient.post<boolean>(
      `${this.baseURL}changeStudyStatus`,
      request
    );
  }

  addReportingImages(request: FormData) {
    return this._httpClient.post<
      GeneralResModel<RisExamResultKeyImagesModel[]>
    >(`${this.examResultApiPATH}AddImages`, request, {
      responseType: "json",
    });
  }

  getAllImagesByFilter(orderId: number) {
    return this._httpClient.get<GeneralResModel<RisExamResultKeyImagesModel[]>>(
      `${this.examResultApiPATH}GetAllImagesByFilter/${orderId}`
    );
  }

  removeReportingImages(request: RisExamResultKeyImagesModel) {
    return this._httpClient.post<
      GeneralResModel<RisExamResultKeyImagesModel[]>
    >(`${this.examResultApiPATH}RemoveImages`, request);
  }

  swapReportingImage(request: RisExamResultKeyImagesModel[]) {
    return this._httpClient.post<
      GeneralResModel<RisExamResultKeyImagesModel[]>
    >(`${this.examResultApiPATH}SwapImage`, request);
  }

  OrderReceivedApiLogList(orderId: number) {
    return this._httpClient.get<GeneralResModel<ExamResult[]>>(
      `${this.baseURL}OrderReceivedApiLog/${orderId}`
    );
  }

}
