import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncrypDepcrypService {
  private readonly secretKey = CryptoJS.enc.Utf8.parse('QpWsTNNzVJd/36STLEVHBI3Zwm9G7+bN');
  private readonly iv = CryptoJS.enc.Utf8.parse('F+9mitZOGridh/Bd');

  constructor() { }
  
  encrypt(dataToEncrypt: string): string {
    const encryptedData = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(dataToEncrypt),
      this.secretKey,
      {
        keySize: 32,
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    const result = encryptedData
      .toString()
      .replaceAll(';', '[1111111]')
      .replaceAll('/', '[2222222]')
      .replaceAll('?', '[3333333]')
      .replaceAll(':', '[4444444]')
      .replaceAll('@', '[5555555]')
      .replaceAll('&', '[6666666]')
      .replaceAll('=', '[7777]')
      .replaceAll('+', '[8888888]')
      .replaceAll('$', '[9999999]')
      .replaceAll(',', '[1211111]');
    return result;
  }

  decrypt(encryptedValue: string): string {

    if (!encryptedValue) {
      return encryptedValue;
    }

    var decrypted = CryptoJS.AES.decrypt(encryptedValue, this.secretKey, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  decryptAsJsonObject(encryptedValue: string): any {
    if (!encryptedValue) {
      return encryptedValue;
    }
  
    const decrypted = CryptoJS.AES.decrypt(encryptedValue, this.secretKey, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
  
    const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
  
    try {
      return JSON.parse(decryptedString);
    } catch (e) {
      console.error('Error parsing decrypted data:', e);
      return null;
    }
  }
}
