<div class="login-body" [ngClass]="{ 'layout-dark': !dark, 'layout-light': dark }" style="
    background: url('../../assets/layout/images/login_bg/2.1.png');
    background-repeat: no;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    min-width: 100vw;
  ">
    <div class="login-content" *ngIf="!isVisibleSigninRecoverCodePanel">
        <div class="login-panel">
            <div class="login-panel-content">
                <div class="twa-fa-verify-wrap">
                    <div class="logo">
                        <img id="login-logo" src="assets/layout/images/logo/labportal.png" alt="LabPortal" />
                    </div>
                    <h4>Enter the 6-digit code from your authenticator app.</h4>
                    <p style="font-style: italic;">This helps us keep your account secure by verifying that it's really
                        you.
                    </p>
                    <br>
                    <div class="otp-wrap">
                        <ng-otp-input #otpInputForInstantCode (onInputChange)="onTwoFaCodeChange($event)"
                            [config]="{length:6, allowNumbersOnly: true}"></ng-otp-input>
                    </div>
                    <br>
                    <div class="mt-3">
                        <button pButton pRipple type="button" icon="pi pi-send" label="Verify" (click)="verifyCode()"
                            [disabled]="!selectedTwoFaCodeValid()"></button>
                    </div>
                </div>

                <div class="mt-2 flex justify-content-between align-items-center">
                    <div>
                        <button pButton pRipple type="button" label="Cancel Signing in" (click)="cancelSigningIn()"
                            class="p-button-info p-button-text"></button>
                    </div>
                    <div class="flex justify-content-end align-items-center">
                        <span>Having Problem?</span>
                        <button pButton pRipple type="button" label="Signin with Backup Code"
                            (click)="signinWithRecoverCode()" class="p-button-info p-button-text"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- SIGNIN WITH BACKUP CODE Dialog START -->
<p-dialog header="Signin with your backup code" [(visible)]="isVisibleSigninRecoverCodePanel" [responsive]="true"
    [minY]="70" [maximizable]="false" [style]="{ width: '30vw' }" [breakpoints]="{ '960px': '75vw' }" [modal]="true"
    (onHide)="closeSigninRecoverCodePanle()">
    <div class="grid">
        <div class="col-12">
            <div class="login-panel-content">
                <div class="twa-fa-verify-wrap text-center">
                    <div class="logo">
                        <img id="login-logo" src="assets/layout/images/logo/labportal.png" alt="LabPortal" />
                    </div>
                    <h4>Enter the 6-digit code from your backup code.</h4>
                    <p style="font-style: italic;">
                        This helps us keep your account secure by verifying that it's really you.
                    </p>
                    <br>
                    <div class="otp-wrap">
                        <ng-otp-input #otpInputForRecoverCode (onInputChange)="onTwoFaCodeChangeForBackup($event)"
                            [config]="{length:6}"></ng-otp-input>
                    </div>
                    <br>
                    <div class="mt-3">
                        <button pButton pRipple type="button" icon="pi pi-send" label="Verify Recover Code"
                            (click)="verifyForRecoverCode()"
                            [disabled]="!selectedTwoFaCodeValid('backupCode')"></button>
                    </div>
                </div>

                <div class="mt-2 flex justify-content-between align-items-center">
                    <div>
                        <button pButton pRipple type="button" label="Cancel Signing in"
                            (click)="closeSigninRecoverCodePanle()" class="p-button-info p-button-text"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-dialog>
<!-- SIGNIN WITH BACKUP CODE Dialog END -->

<!-- Disable 2FA Confirmation Dialog START-->
<p-confirmDialog header="Confirmation" key="confirmTwoFADisable" icon="pi pi-exclamation-triangle"
    message="Are you sure you want to proceed?" [style]="{ width: '350px' }" acceptButtonStyleClass="p-button-text"
    rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<!-- Disable 2FA Confirmation Dialog END-->