import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import pages from '../../../assets/data/configuration.json';
import { Configuration } from '../models/configuration.model';


@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private config : Configuration = new Configuration();
  constructor(private http: HttpClient) { 
    this.config = <Configuration>pages;
  }
  getSettings(){
    return this.config;
  }
  getJSONwithAPI(){
    return this.http.get<Configuration>(`../../../assets/data/configuration.json`);
   }
}
