import { AnalyzerService } from "./analyzer/analyzer.service";
import { DataService } from "./data-service/data.service";
import { DicomService } from "./dicom/dicom.service";
import { EncryptUrlService } from "./encrypt/encrypt-url.service";
import { EncrypDepcrypService } from "./encryption/encryp-depcryp.service";
import { ExamInfoService } from "./exam-info/exam-info.service";
import { ExamResultService } from "./exam-result/exam-result.service";
import { ImageService } from "./image-service/image.service";
import { InventoryService } from "./inventory/inventory.service";
import { LabSetupService } from "./lab-setup/lab-setup.service";
import { OpenAiService } from "./open-ai/open-ai.service";
import { PetPatientService } from "./pet-patient/pet-patient.service";
import { ReportService } from "./report/report.service";
import { UtilityService } from "./utility/utility.service";

export const services: any[] = [
  DicomService,
  ExamResultService,
  ExamInfoService,
  ReportService,
  PetPatientService,
  ImageService,
  LabSetupService,
  InventoryService,
  DataService,
  EncryptUrlService,
  OpenAiService,
  UtilityService,
  EncrypDepcrypService,
  AnalyzerService
];

export * from "./analyzer/analyzer.service";
export * from "./data-service/data.service";
export * from "./dicom/dicom.service";
export * from "./encrypt/encrypt-url.service";
export * from "./encryption/encryp-depcryp.service";
export * from "./exam-info/exam-info.service";
export * from "./exam-result/exam-result.service";
export * from "./image-service/image.service";
export * from "./inventory/inventory.service";
export * from "./lab-setup/lab-setup.service";
export * from "./open-ai/open-ai.service";
export * from "./pet-patient/pet-patient.service";
export * from "./report/report.service";
export * from "./utility/utility.service";

