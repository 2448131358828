import { Injectable } from '@angular/core';
import { OpenAiModel } from 'src/app/_models';
import { StudyModel } from 'src/app/_models/study.model';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class OpenAiService {

  private apiPATH = "OpenAI/";

  constructor(
    private _api: ApiService,
  ) { }

  getAIReport(request: OpenAiModel) {
    return this._api.post<any>(`${this.apiPATH}getAIReport`, request);
  }

  dicomToImageExtract(orderId: number, accessionNo: string) {
    return this._api.post<StudyModel>(`${this.apiPATH}dicomToImageExtract/${orderId}/${accessionNo}`, {});
  }

  finalizeReportLoad(orderId: number) {
    return this._api.post<StudyModel>(`${this.apiPATH}finalizeReportLoad/${orderId}`, {});
  }
}
