<div class="singup-top grid">
  <div class="col-12 md:col-4">
    <div class="signup-logo">
      <img src="assets/layout/images/logo/logo.png" alt="mirage-layout" />
    </div>
  </div>
  <div class="col-12 md:col-8 text-right">
    <ul>
      <!-- <li><a href="https://radportal.net/">HOME</a></li>
      <li><a href="https://radportal.net/#about">ABOUT</a></li>
      <li><a href="https://radportal.net/#contact">CONTACT US</a></li> -->
    </ul>
  </div>
</div>

<div class="singup">
  <div class="text-center">
    <h2 class="mt-50 mb-5">{{ registrationHeader }}</h2>
    <div hidden class="show_wrap all_radiologist-wrap" style="margin-left: 10%">
      <div class="org-name__mix-all all_radiologist-inner">
        <div class="">
          <div class="flex">
            <p class="mr-1">I am a Radiologist</p>
            <p-inputSwitch [disabled]="IsVerifySuccessfull" [(ngModel)]="isRadiographer" class="nmt-9"
              (onChange)="radiographerShow()"></p-inputSwitch>
            <p class="ml-1">Radiographer</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <form [formGroup]="radiogistForm">
    <p-card>
      <div class="p-fluid signup-new-style">
        <div class="p-formgrid grid" *ngIf="!IsVerifySuccessfull">
          <div class="field col-12 md:col-6 lg:col-6">
            <div class="grid">
              <div class="col-12 sm:col-12 md:col-12 lg:col-5">
                <div class="mb-2">
                  <label for="email">Email<span class="red">*</span></label>
                </div>
                <div>
                  <input (keyup)="checkEmail($event)" *ngIf="
                      (disable == true && LineId != null) ||
                      IsEmailVerified == true
                    " type="email" disabled="true" pInputText formControlName="Email" [(ngModel)]="selectedEmail"
                    placeholder="Email" id="email" />

                  <input (keyup)="checkEmail($event)" *ngIf="
                      disable == false &&
                      (IsEmailVerified == false || IsEmailVerified == null)
                    " type="email" pInputText formControlName="Email" [(ngModel)]="selectedEmail" placeholder="Email"
                    id="email" />

                  <i *ngIf="IsEmailVerified == true" style="color: green" class="pi pi-check-circle"></i>
                </div>

                <div *ngIf="
                    radiogistForm.controls['Email'].dirty &&
                    IsEmailVerified == false
                  ">
                  Email verification failed!
                </div>

                <p-message severity="error" *ngIf="emailExists && radiogistForm.controls['Email'].dirty"
                  class="danger exist-email" text="Email already exists."></p-message>
              </div>
              <div class="col-12 sm:col-12 md:col-12 lg:col-5" *ngIf="
                  IsSentVerificationCode == true && IsVerifySuccessfull != true
                ">
                <div class="mb-2">
                  <label for="typeCode">Type Code</label>
                </div>
                <div class="otp-wrap">
                  <ng-otp-input id="typeCode" #ngOtpInputEmail (onInputChange)="onEmailOtpChange($event)"
                    [config]="{ length: 6 }"></ng-otp-input>
                </div>
              </div>
              <div class="col-12 sm:col-12 md:col-12 lg:col-2 mt-3">
                <div class="mt-15" *ngIf="
                    IsCountDownTimeOut == false &&
                    (IsVerifySuccessfull == false ||
                      IsVerifySuccessfull == null)
                  ">
                  {{ display }}
                </div>
              </div>
            </div>
          </div>

          <div class="field col-12 md:col-6 lg:col-6">
            <div class="grid">
              <div class="col-12 sm:col-12 md:col-12 lg:col-5">
                <div class="mb-2">
                  <label for="phoneNo">Phone No.<span class="red">*</span></label>
                </div>
                <div class="relative check-mark pdl-0">
                  <div class="p-inputgroup phone-inputgroup">
                    <span class="phone-inputgroup-addon">
                      <p-dropdown [disabled]="IsPhoneVerified == true" [options]="CountryWithCode"
                        formControlName="phoneCode" [(ngModel)]="selectedPhoneCode" optionLabel="name" [filter]="true"
                        filterBy="name">
                        <ng-template pTemplate="selectedItem">
                          <div class="country-item country-item-value" *ngIf="selectedPhoneCode">
                            <img src="../../../../../assets/demo/flags/flag_placeholder.png" [class]="
                                'flag flag-' +
                                selectedPhoneCode.code.toLowerCase()
                              " />
                            &nbsp;<span>{{ selectedPhoneCode.dial_code }}</span>
                          </div>
                        </ng-template>
                        <ng-template let-country pTemplate="item">
                          <div class="country-item">
                            <img src="../../../../../assets/demo/flags/flag_placeholder.png" [class]="
                                'flag flag-' + country.code.toLowerCase()
                              " />
                            &nbsp;<span>{{ country.name }} {{ country.dial_code }}</span>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </span>
                    <input *ngIf="IsPhoneVerified == true" (keyup)="checkPhone($event)" disabled type="text" pInputText
                      formControlName="PhoneNo" [(ngModel)]="selectedPhoneNo" placeholder="Phone No." />

                    <input *ngIf="
                        IsPhoneVerified == false || IsPhoneVerified == null
                      " (keyup)="checkPhone($event)" type="text" pInputText formControlName="PhoneNo"
                      [(ngModel)]="selectedPhoneNo" placeholder="Phone No." />
                  </div>

                  <i *ngIf="IsPhoneVerified == true" style="color: green" class="pi pi-check-circle"></i>
                </div>
                <div *ngIf="
                    radiogistForm.controls['PhoneNo'].dirty &&
                    IsPhoneVerified == false
                  ">
                  Phone number verification failed!
                </div>

                <p-message severity="error" *ngIf="PhoneExists && radiogistForm.controls['PhoneNo'].dirty"
                  class="danger exist-email" text="Phone number already exists.">
                </p-message>
              </div>
              <div class="col-12 sm:col-12 md:col-12 lg:col-5" *ngIf="
                  IsSentVerificationCode == true && IsVerifySuccessfull != true
                ">
                <div class="mb-2">
                  <label for="typeOtp">Type OTP</label>
                </div>
                <div>
                  <ng-otp-input id="typeOtp" #ngOtpInputPhone (onInputChange)="onOtpPhoneChange($event)"
                    [config]="{ length: 6, disableAutoFocus: true }"></ng-otp-input>
                </div>
              </div>
              <div class="col-12 sm:col-12 md:col-12 lg:col-2">
                <div style="margin-top: 24px">
                  <p-button *ngIf="
                      IsSentVerificationCode == true &&
                      IsVerifySuccessfull != true
                    " icon="pi pi-refresh" title="Reset" (onClick)="reset()"></p-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="p-fluid grid" *ngIf="IsVerifySuccessfull">
          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-4">
            <div class="field grid signup-input-wrapper">
              <label htmlfor="email" class="col-12 mb-2 md:col-3 md:mb-0">Email<span class="red">*</span></label>
              <div class="col-12 md:col-9">
                <div class="relative check-mark pdl-0">
                  <div class="p-inputgroup phone-inputgroup">
                    <input (keyup)="checkEmail($event)" *ngIf="
                        (disable == true && LineId != null) ||
                        IsEmailVerified == true
                      " type="email" disabled="true" pInputText formControlName="Email" [(ngModel)]="selectedEmail"
                      placeholder="Email" id="email" />

                    <input (keyup)="checkEmail($event)" *ngIf="
                        disable == false &&
                        (IsEmailVerified == false || IsEmailVerified == null)
                      " type="email" pInputText formControlName="Email" [(ngModel)]="selectedEmail" placeholder="Email"
                      id="email" />

                    <i *ngIf="IsEmailVerified == true" style="color: green" class="pi pi-check-circle"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-4">
            <div class="field grid signup-input-wrapper">
              <label htmlfor="phoneNo" class="col-12 mb-2 md:col-3 md:mb-0">Phone No.<span class="red">*</span></label>
              <div class="col-12 md:col-9">
                <div class="relative check-mark pdl-0">
                  <div class="p-inputgroup phone-inputgroup">
                    <span class="phone-inputgroup-addon">
                      <p-dropdown [disabled]="IsPhoneVerified == true" [options]="CountryWithCode"
                        formControlName="phoneCode" [(ngModel)]="selectedPhoneCode" optionLabel="name" [filter]="true"
                        filterBy="name">
                        <ng-template pTemplate="selectedItem">
                          <div class="country-item country-item-value" *ngIf="selectedPhoneCode">
                            <img src="../../../../../assets/demo/flags/flag_placeholder.png" [class]="
                                'flag flag-' +
                                selectedPhoneCode.code.toLowerCase()
                              " />
                            &nbsp;<span>{{ selectedPhoneCode.dial_code }}</span>
                          </div>
                        </ng-template>
                        <ng-template let-country pTemplate="item">
                          <div class="country-item">
                            <img src="../../../../../assets/demo/flags/flag_placeholder.png" [class]="
                                'flag flag-' + country.code.toLowerCase()
                              " />
                            &nbsp;<span>{{ country.name }} {{ country.dial_code }}</span>
                          </div>
                        </ng-template>
                      </p-dropdown>
                    </span>
                    <input *ngIf="IsPhoneVerified == true" (keyup)="checkPhone($event)" disabled type="text" pInputText
                      formControlName="PhoneNo" [(ngModel)]="selectedPhoneNo" placeholder="Phone No." />

                    <input *ngIf="
                        IsPhoneVerified == false || IsPhoneVerified == null
                      " (keyup)="checkPhone($event)" type="text" pInputText formControlName="PhoneNo"
                      [(ngModel)]="selectedPhoneNo" placeholder="Phone No." />
                  </div>

                  <i *ngIf="IsPhoneVerified == true" style="color: green" class="pi pi-check-circle"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-2"></div>

          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-4">
            <div class="field grid signup-input-wrapper">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Password<span class="red">*</span></label>
              <div class="col-12 md:col-9">
                <p-password [(ngModel)]="selectedPassword" formControlName="Password" [toggleMask]="true"></p-password>
              </div>
            </div>
          </div>

          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-4">
            <div class="field grid signup-input-wrapper">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Confirm Password<span
                  class="red">*</span></label>
              <div class="col-12 md:col-9">
                <p-password [(ngModel)]="selectedConfirmPassword" formControlName="ConfirmPassword"
                  [toggleMask]="true"></p-password>
              </div>

              <div *ngIf="
                  radiogistForm.controls['ConfirmPassword'].dirty &&
                  radiogistForm.hasError('mismatch')
                ">
                Password mismatch
              </div>
            </div>
          </div>
          <div class="col-12 md:col-2"></div>

          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-4">
            <div class="field grid">
              <label htmlfor="firstname" class="col-12 mb-2 md:col-3 md:mb-0">First Name<span
                  class="red">*</span></label>
              <div class="col-12 md:col-9">
                <input type="text" pInputText formControlName="FName" [(ngModel)]="selectedFName"
                  placeholder="First Name" />
              </div>
            </div>
          </div>
          <div class="col-12 md:col-1"></div>

          <div class="col-12 md:col-4">
            <div class="field grid">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Last Name<span class="red">*</span></label>
              <div class="col-12 md:col-9">
                <input type="text" pInputText formControlName="LName" [(ngModel)]="selectedLName"
                  placeholder="Last Name" />
              </div>
            </div>
          </div>
          <div class="col-12 md:col-2"></div>
          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-4">
            <div class="field grid">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Line Id</label>
              <div class="col-12 md:col-9">
                <input *ngIf="disable == true && LineId != null" type="text" disabled="true" pInputText
                  formControlName="LineId" [(ngModel)]="selectedLineId" />
                <input *ngIf="disable == false" type="text" pInputText formControlName="LineId"
                  [(ngModel)]="selectedLineId" />
              </div>
            </div>
          </div>

          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-4">
            <div class="field grid">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Medical License</label>
              <div class="col-12 md:col-9">
                <input type="text" pInputText formControlName="MedicalLincence" [(ngModel)]="selectedMedicalLincence" />
              </div>
            </div>
          </div>
          <div class="col-12 md:col-2"></div>

          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-4">
            <div class="field grid">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Country<span class="red">*</span></label>
              <div class="col-12 md:col-9">
                <p-dropdown [options]="countryList" [(ngModel)]="selectedCountry" formControlName="Country"
                  (onChange)="loadProvince()" placeholder="Select a country" optionLabel="CountryName"
                  [showClear]="true"></p-dropdown>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-1"></div>

          <div class="col-12 md:col-4">
            <div class="field grid">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Province</label>
              <div class="col-12 md:col-9">
                <!-- <input  type="text" pInputText formControlName="Province" /> -->

                <p-dropdown [options]="ProvinceList" [(ngModel)]="selectedProvince" (onChange)="loadDistrict()"
                  formControlName="Province" placeholder="Select a Province" optionLabel="ProvinceName"
                  [showClear]="true"></p-dropdown>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-2"></div>

          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-4">
            <div class="field grid">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">District</label>
              <div class="col-12 md:col-9">
                <!-- <input type="text" pInputText formControlName="District" 
              placeholder="PhoneNo" />  -->

                <p-dropdown [options]="districtList" [(ngModel)]="selectedDistrict" formControlName="District"
                  (onChange)="loadSubDistrict()" placeholder="Select a District" optionLabel="DistrictName"
                  [showClear]="true"></p-dropdown>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-1"></div>

          <div class="col-12 md:col-4">
            <div class="field grid">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Sub-District</label>
              <div class="col-12 md:col-9">
                <!-- <input type="text" pInputText formControlName="SubDistrict" 
              placeholder="PhoneNo" />  -->

                <p-dropdown [options]="SubDistrictList" [(ngModel)]="selectedSubDistrict" formControlName="SubDistrict"
                  placeholder="Select a Sub-District" optionLabel="SubdistrictName" [showClear]="true"></p-dropdown>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-2"></div>

          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-4">
            <div class="field grid">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Zip Code</label>
              <div class="col-12 md:col-9">
                <!-- <input type="text" pInputText formControlName="ZipCode" [(ngModel)]="selectedZipCode"
                  placeholder="Zip Code" /> -->

                <p-dropdown [options]="postalCodeList" [(ngModel)]="selectedZipCode" formControlName="ZipCode"
                  placeholder="Select a Zip Code" [showClear]="true">
                  <ng-template let-item pTemplate="item">
                    <span>{{item.PlaceName}} ({{item.PostalCode}})</span>
                  </ng-template>
                  <ng-template let-selectedZipCode pTemplate="selectedItem">
                    <span>{{selectedZipCode.PlaceName}} ({{selectedZipCode.PostalCode}})</span>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>

          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-4">
            <div class="field grid">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Subspecialties</label>
              <div class="col-12 md:col-9">
                <p-multiSelect [options]="SubSpecialtyList" [(ngModel)]="selectedSubSpecialty"
                  formControlName="SubSpecialty" placeholder="Select Subspecialties" optionLabel="SubspecialtyName"
                  [showClear]="true"></p-multiSelect>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-2"></div>

          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-4"></div>

          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-4">
            <div class="field grid">
              <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Apply Hospital<span
                  class="red">*</span></label>
              <div class="col-12 md:col-9">
                <p-multiSelect [options]="siteList" [(ngModel)]="selectedSite" formControlName="HospitalIds"
                  placeholder="Select Hospital" optionLabel="OrgName" [showClear]="true" filter="true"></p-multiSelect>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-2"></div>

          <div class="col-12 md:col-1"></div>
          <div class="col-12 md:col-1 rad-profile">
            <p-image [src]="profileImageUrl" alt="Image" width="250" [preview]="false"></p-image>

            <p-fileUpload class="ui-state-focus" name="myFile[]" customUpload="true"
              (uploadHandler)="myUploader($event)" (onUpload)="onUpload($event)" auto="auto" accept="image/*">
            </p-fileUpload>
          </div>
          <div class="col-12 md:col-4"></div>
          <div class="col-12 md:col-2">
            <p-button class="upload-feature-btn" icon="pi pi-upload" label="Upload Document"
              (onClick)="OpenDocumentPopUp()"></p-button>
          </div>
        </div>
      </div>
    </p-card>

    <div class="p-formgrid grid mt-2" *ngIf="IsVerifySuccessfull == true">
      <div class="col-12 md:col-9">
        <p-checkbox formControlName="TearmAndCondition" name="groupname" value="val1"
          [(ngModel)]="selectedTermAndCon"></p-checkbox>
        I have read and agreed to the
        <a> VetPortal Account Terms of Use </a>
        <br />
      </div>
      <div class="col-12 md:col-3 text-right">
        <p-button (onClick)="startOverPage()" icon="pi pi-spinner" class="mr-2" label="Start Over"></p-button>

        <p-button icon="pi pi-check" label="Finish" [disabled]="
            !radiogistForm.valid ||
            !selectedTermAndCon
          " (onClick)="saveOrUpdateRediologist()"></p-button>
      </div>
    </div>

    <div class="send-verfication text-center mt-50">
      <p-button icon="pi pi-send" *ngIf="IsSentVerificationCode == false" [disabled]="
          emailExists == true ||
          PhoneExists == true ||
          !selectedPhoneNo ||
          !selectedEmail ||
          !isEmailValid() ||
          selectedPhoneNo == null ||
          selectedPhoneNo == undefined
        " label="Send Verification" (onClick)="sentVerificationCode()"></p-button>
    </div>

    <div class="confirm-second-step mt-50">
      <div class="resend-btn" *ngIf="showOrHideResendButton == true">
        <p-button icon="pi pi-send" *ngIf="
            IsVerifySuccessfull == false &&
            IsSentVerificationCode == true &&
            (IsCountDownTimeOut == true ||
              (IsEmailVerified == false && IsPhoneVerified == true) ||
              (IsEmailVerified == false && IsPhoneVerified == false) ||
              (IsEmailVerified == true && IsPhoneVerified == false))
          " label="Resend Verification Code" (onClick)="sentVerificationCode()"></p-button>
      </div>

      <div class="grid verify-btn" *ngIf="showOrHideResendButton == false">
        <div class="col-12 md:col-5"></div>
        <div class="verify-btn" *ngIf="
            (IsEmailVerified == false && IsPhoneVerified == false) ||
            (IsEmailVerified == null && IsPhoneVerified == null) ||
            IsEmailVerified != IsPhoneVerified
          ">
          <p-button icon="fa fa-check-square-o" *ngIf="
              IsVerifySuccessfull == false &&
              IsSentVerificationCode == true &&
              IsCountDownTimeOut == false
            " label="Confirm" (onClick)="VerifyOtp()"></p-button>
        </div>
      </div>
    </div>
  </form>
</div>

<p-dialog header="Upload Document" [(visible)]="displayDocumentUploadDialog" (onHide)="closeDocumentDialog()"
  [modal]="true" [responsive]="true" [minY]="70" [maximizable]="false" [style]="{ width: '80vw' }"
  [breakpoints]="{ '960px': '75vw' }">
  <p-panel>
    <div class="document_upload_form">
      <div class="formgroup-inline">
        <div class="field">
          <label htmlFor="type" class="d-block mb-1">Type</label>
          <p-dropdown id="type" [options]="documentTypedDopdownItems" optionLabel="name" required name="Type"
            [(ngModel)]="selectedDocumentType"></p-dropdown>
        </div>
        <div class="field">
          <label htmlFor="document" class="d-block mb-1">Select file</label>
          <input pInputText id="document" type="file" name="File" [(ngModel)]="selecetdInput"
            accept="application/pdf,image/png,image/jpeg" (change)="documentUploader($event)" />
        </div>
        <div class="field">
          <label htmlFor="title" class="d-block mb-1">Title</label>
          <input class="min-w-515" pInputText id="title" type="text" placeholder="Enter Title" required name="Title"
            [(ngModel)]="userDocumet.Title" />
        </div>
        <p-button class="mt-20" icon="pi pi-upload" (onClick)="uploadDocuments()" label="Upload"></p-button>
      </div>

      <div class="uploaded_document_list_table">
        <p-table #dt1 [value]="newUser.hrEmpdocuments" dataKey="type" [rows]="10" [rowHover]="true"
          styleClass="p-datatable-gridlines" [paginator]="true" responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th class="max-w-150" pSortableColumn="Type">
                Type <p-sortIcon field="Type"></p-sortIcon>
              </th>
              <th class="max-w-110" pSortableColumn="Format">
                Format <p-sortIcon field="Format"></p-sortIcon>
              </th>
              <th pSortableColumn="Title">
                Title <p-sortIcon field="Title"></p-sortIcon>
              </th>
              <th class="width-70">Action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td class="max-w-150">
                {{ rowData.Type }}
              </td>
              <td class="max-w-110">
                <div class="flex justify-content-center align-items-center">
                  <i class="mr-2" [ngClass]="
                      rowData.Format === 'pdf'
                        ? 'pi pi-file-pdf'
                        : 'pi pi-image'
                    "></i>
                  <span>({{ rowData.Format }})</span>
                </div>
              </td>
              <td class="td-min-w">
                {{ rowData.Title }}
              </td>
              <td class="width-70">
                <button pButton type="button" icon="fa fa-regular fa-trash" title="Delete the document"
                  class="p-button-danger p-button-rounded" (click)="deleteOrderDocument(rowData)"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="4" class="text-center">No data found.</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="loadingbody">
            <tr>
              <td colspan="4" class="text-center">
                Loading uploaded document data. Please wait.
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </p-panel>
</p-dialog>

<div *ngIf="isLoaderVisible" class="loading-overlay">
  <div class="loader"></div>
</div>