import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptUrlService {

  constructor() { }

  encryptParameter(param: string, key: string): string {
    return CryptoJS.AES.encrypt(param, key).toString();
  }

  decryptParameter(encryptedParam: string, key: string): string {
    const bytes = CryptoJS.AES.decrypt(encryptedParam, key);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
}
