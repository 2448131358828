import { Injectable } from "@angular/core";
import {
  AnalyzerDietarytypeMapping,
  AnalyzerDietarytypeModel,
  AnalyzerModel,
  DietarytypeModel,
  GeneralResModel,
} from "src/app/_models";
import { CommonResponse } from "src/app/_models/common-response.modle";
import { ApiService } from "../api/api.service";

@Injectable({
  providedIn: "root",
})
export class AnalyzerService {
  private apiPATH = "Analyzer/";

  constructor(private _api: ApiService) {}

  getAllAnalyzer(orgId: number) {
    return this._api.get<GeneralResModel<AnalyzerModel[]>>(
      `${this.apiPATH}GetAllAnalyzer/${orgId}`
    );
  }

  getAllAnalyzerList(orgId: number) {
    return this._api.get<GeneralResModel<AnalyzerModel[]>>(
      `${this.apiPATH}GetAllAnalyzerList/${orgId}`
    );
  }

  GetAnalyzer(analyzerId: number) {
    return this._api.get<CommonResponse>(
      `${this.apiPATH}GetAnalyzer/${analyzerId}`
    );
  }

  AddAnalyzer(body: AnalyzerModel) {
    return this._api.post<CommonResponse>(`${this.apiPATH}AddAnalyzer`, body);
  }

  UpdateAnalyzer(body: AnalyzerModel) {
    return this._api.post<CommonResponse>(
      `${this.apiPATH}UpdateAnalyzer`,
      body
    );
  }

  getAllAnalyzerByExam(examId: number, orgId: number) {
    return this._api.get<GeneralResModel<AnalyzerModel[]>>(
      `${this.apiPATH}GetAllAnalyzerByExam/${examId}/${orgId}`
    );
  }

  getDietarytypeByAnalyzer(analyzerId: number) {
    return this._api.get<GeneralResModel<AnalyzerDietarytypeModel[]>>(
      `${this.apiPATH}GetDietarytypeByAnalyzer/${analyzerId}`
    );
  }

  getDietarytypeByAnalyzerIds(analyzerIds: number[]) {
    const params = analyzerIds.map(id => `analyzerIds=${id}`).join('&');
    return this._api.get<GeneralResModel<AnalyzerDietarytypeModel[]>>(
      `${this.apiPATH}GetDietarytypeByAnalyzerIds?${params}`
    );
  }
  

  GetSelectedDietarytypeByAnalyzer(analyzerId: number, orgId: number) {
    return this._api.get<GeneralResModel<DietarytypeModel[]>>(
      `${this.apiPATH}GetSelectedDietarytypeByAnalyzer/${analyzerId}/${orgId}`
    );
  }

  GetAllDietarytype(orgId: number) {
    return this._api.get<GeneralResModel<DietarytypeModel[]>>(
      `${this.apiPATH}GetAllDietarytype/${orgId}`
    );
  }

  GetAllAnalyzerDietarytype(orgId: number) {
    return this._api.get<GeneralResModel<AnalyzerDietarytypeModel[]>>(
      `${this.apiPATH}GetAllAnalyzerDietarytype/${orgId}`
    );
  }

  GetDietarytype(dietaryTypeId: number) {
    return this._api.get<CommonResponse>(
      `${this.apiPATH}GetDietarytype/${dietaryTypeId}`
    );
  }
  AddDietarytype(body: DietarytypeModel) {
    return this._api.post<CommonResponse>(
      `${this.apiPATH}AddDietarytype`,
      body
    );
  }

  UpdateDietarytype(body: DietarytypeModel) {
    return this._api.post<CommonResponse>(
      `${this.apiPATH}UpdateDietarytype`,
      body
    );
  }

  GetAnalyzerDietarytype(analyzerDietarytypeModelId: number) {
    return this._api.get<CommonResponse>(
      `${this.apiPATH}GetAnalyzerDietarytype/${analyzerDietarytypeModelId}`
    );
  }

  AddAnalyzerDietarytype(body: AnalyzerDietarytypeModel) {
    return this._api.post<CommonResponse>(
      `${this.apiPATH}AddAnalyzerDietarytype`,
      body
    );
  }

  UpdateAnalyzerDietarytype(body: AnalyzerDietarytypeModel) {
    return this._api.post<CommonResponse>(
      `${this.apiPATH}UpdateAnalyzerDietarytype`,
      body
    );
  }

  GetNextSlAnalyzer(orgId: number) {
    return this._api.get<any>(`${this.apiPATH}GetNextSlAnalyzer/${orgId}`);
  }

  GetNextSlDietarytype(orgId: number) {
    return this._api.get<any>(`${this.apiPATH}GetNextSlDietarytype/${orgId}`);
  }

  GetNextSlAnalyzerDietarytype(orgId: number) {
    return this._api.get<any>(
      `${this.apiPATH}GetNextSlAnalyzerDietarytype/${orgId}`
    );
  }

  AddDietarytypMapping(dietarytypeMapping: AnalyzerDietarytypeMapping) {
    try {
      return this._api.post<CommonResponse>(
        `${this.apiPATH}AddDietarytypMapping`,
        dietarytypeMapping
      );
    } catch (error) {
      throw error;
    }
  }

  DeleteAnalyzerDietarytype(body: AnalyzerDietarytypeModel) {
    return this._api.post<CommonResponse>(
      `${this.apiPATH}DeleteAnalyzerDietarytype`,
      body
    );
  }
}
