import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import {
  ClientPatientResponseModel,
  GeneralResModel,
  HisRegistrationModel,
  PatientInformationModel,
  PmsBreedModel,
  PmsClientModel,
  PmsPetColorModel,
  PmsSpeciesModel,
} from "src/app/_models";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PetPatientService {
  private baseURL = `${environment.apiUrl}`;
  private apiPATH = this.baseURL + `PetPatient/`;

  constructor(private _httpClient: HttpClient) {}

  // PET PATIENT APIs START
  createPatient(request: HisRegistrationModel) {
    return this._httpClient.post<GeneralResModel<HisRegistrationModel>>(
      `${this.apiPATH}CreatePatient`,
      request
    );
  }

  updatePatient(request: HisRegistrationModel) {
    return this._httpClient.post<GeneralResModel<HisRegistrationModel>>(
      `${this.apiPATH}UpdatePatient`,
      request
    );
  }

  deletePatient(request: HisRegistrationModel) {
    return this._httpClient.post<GeneralResModel<string>>(
      `${this.apiPATH}DeletePatient`,
      request
    );
  }

  getPatientListByOrgId(orgId: number) {
    return this._httpClient
      .get<GeneralResModel<HisRegistrationModel[]>>(
        `${this.apiPATH}GetPatientListByOrgId/${orgId}`
      )
      .pipe(
        map((items) => {
          const genders = new Map<string, string>([
            ["M", "Male"],
            ["F", "Female"],
            ["O", "Unkown"],
          ]);
          items.Result.map((i) => {
            i.GenderText = genders.get(i.Gender);
          });
          return items;
        })
      );
  }

  getBreeds() {
    return this._httpClient.get<GeneralResModel<PmsBreedModel[]>>(
      `${this.apiPATH}GetPmsBreeds`
    );
  } 
  
  getPmsBreedsByOrgId(orgId:number) {
    return this._httpClient.get<GeneralResModel<PmsBreedModel[]>>(
      `${this.apiPATH}GetPmsBreedsByOrgId/${orgId}`
    );
  }

  getSpecies() {
    return this._httpClient.get<GeneralResModel<PmsSpeciesModel[]>>(
      `${this.apiPATH}GetPmsSpeciess`
    );
  }

  getPmsSpeciessByOrgId(orgId:number) {
    return this._httpClient.get<GeneralResModel<PmsSpeciesModel[]>>(
      `${this.apiPATH}GetPmsSpeciessByOrgId/${orgId}`
    );
  }

  getPetColors() {
    return this._httpClient.get<GeneralResModel<PmsPetColorModel[]>>(
      `${this.apiPATH}GetPmsPetColors`
    );
  }  
  
  getPmsPetColorsByOrgId(orgId:number) {
    return this._httpClient.get<GeneralResModel<PmsPetColorModel[]>>(
      `${this.apiPATH}GetPmsPetColorsByOrgId/${orgId}`
    );
  }
  // PET PATIENT APIs END

  // PET OWNER APIs START
  getPmsClientsByOrgId(orgid: number) {
    return this._httpClient.get<GeneralResModel<PmsClientModel[]>>(
      `${this.apiPATH}GetPmsClientsByOrgId/${orgid}`
    );
  }

  getPmsClients() {
    return this._httpClient.get<GeneralResModel<PmsClientModel[]>>(
      `${this.apiPATH}GetPmsClients`
    );
  }

  GetPmsClientById(clientId: number) {
    return this._httpClient.get<GeneralResModel<PmsClientModel>>(
      `${this.apiPATH}GetPmsClientById/${clientId}`
    );
  }

  getClientAutoComplete(
    filter: string,
    type: string,
    status: string,
    orderId: number
  ) {
    return this._httpClient.get<GeneralResModel<PmsClientModel[]>>(
      `${this.apiPATH}GetClientAutoComplete/${filter}/${type}/${status}/${orderId}`
    );
  }

  getClientAutoCompleteByOrgOwner(
    filter: string,
    type: string,
    status: string,
    orgId: number
  ) {
    return this._httpClient.get<GeneralResModel<PmsClientModel[]>>(
      `${this.apiPATH}GetClientAutoComplete/${filter}/${type}/${status}/${orgId}`
    );
  }

  createPmsClient(request: PmsClientModel) {
    return this._httpClient.post<GeneralResModel<PmsClientModel>>(
      `${this.apiPATH}CreatePmsClient`,
      request
    );
  }

  updatePmsClient(request: PmsClientModel) {
    return this._httpClient.post<GeneralResModel<PmsClientModel>>(
      `${this.apiPATH}UpdatePmsClient`,
      request
    );
  }

  deletePmsClient(request: PmsClientModel) {
    return this._httpClient.post<GeneralResModel<string>>(
      `${this.apiPATH}DeletePmsClient`,
      request
    );
  }
  // PET OWNER APIs END

  getClientPatientAutoComplete(
    filter: string,
    type: string,
    status: string,
    orgId?: number
  ) {
    return this._httpClient.get<GeneralResModel<ClientPatientResponseModel[]>>(
      `${this.apiPATH}GetClientPatientAutoComplete/${filter}/${type}/${status}/${orgId}`
    );
  }

  getPatientInfoById(regId: number) {
    return this._httpClient
      .get<GeneralResModel<PatientInformationModel>>(
        `${this.apiPATH}GetPatientInfoById/${regId}`
      )
      .pipe(
        map((items) => {
          const genders = new Map<string, string>([
            ["M", "Male"],
            ["F", "Female"],
            ["O", "Unkown"],
          ]);

          const nuters = new Map<boolean, string>([
            [true, "Yes"],
            [false, "No"],
          ]);

          let i = items.Result;
          i.GenderText = genders.get(i.Gender);
          i.OwnerGenderText = genders.get(i.OwnerGender);
          i.IsNuterText = nuters.get(i.IsNuter);
          i.OwnerFullName = i.OwnerFnameEng + " " + i.OwnerLnameEng;
          return items;
        })
      );
  }
}
