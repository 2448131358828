import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ConfirmationService } from "primeng/api";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { User } from "./_models/user-models/user";
import { ImageService } from "./_services";
import { AuthService } from "./_services/auth/auth.service";
import { AlertifyService } from "./_services/common/alertify.service";
import { AppMainComponent } from "./app.main.component";

@Component({
  selector: "app-topbar",
  template: `
    <div class="layout-topbar">
      <div class="layout-topbar-wrapper">
        <div class="layout-topbar-left">
          <div class="layout-topbar-logo-wrapper">
            <a href="#" class="layout-topbar-logo">
              <img
                src="assets/layout/images/logo/logo.png"
                alt="mirage-layout"
              />
            </a>
          </div>

          <a
            href="#"
            class="sidebar-menu-button"
            (click)="appMain.onMenuButtonClick($event)"
          >
            <i class="pi pi-bars"></i>
          </a>

          <a
            href="#"
            class="topbar-menu-mobile-button"
            (click)="appMain.onTopbarMobileMenuButtonClick($event)"
          >
            <i class="pi pi-ellipsis-v"></i>
          </a>
          <img *ngIf="!isOrgImage"
          class="mr-2 mt-2"
          src="{{ demoHospitalLogo }}"
          alt=""
          width="50"
          height="50"
        />
          <img *ngIf="isOrgImage"
            class="mr-2 mt-2"
            src="data:image/jpeg;base64, {{ demoHospitalLogo }}"
            alt=""
            width="50"
            height="50"
          />
        </div>
        <div class="brand-logo">
          <h5>{{ loginUserOrgName}}</h5>
        </div>
        <div class="layout-topbar-right fadeInDown">
          <ul class="layout-topbar-actions">
            <li>
              <span class="landing-home" (click)="alertForSetPreferredLandingPage()" title="Set the current page as Home">
                <i class="pi pi-home"></i>
              </span>
            </li>
            <li
              #profile
              class="topbar-item profile-item"
              [ngClass]="{
                'active-topmenuitem': appMain.activeTopbarItem === profile
              }"
            >
              <a href="#" (click)="appMain.onTopbarItemClick($event, profile)">
                <span
                  class="profile-image-wrapper"
                  *ngIf="imageUrl != null; else elseBlockImg"
                >
                  <img [src]="imageUrl" alt="User Photo" />
                </span>
                <ng-template #elseBlockImg>
                  <span class="profile-image-wrapper">
                    <img src="{{ defaultProfilePhoto }}" alt="User Photo" />
                  </span>
                </ng-template>

                <span class="profile-info-wrapper">
                  <h3>{{ currentUserName }}</h3>
                </span>
              </a>
              <ul class="profile-item-submenu fadeInDown">
                <li class="layout-submenu-item">
                  <i class="pi pi-user icon icon-3"></i>
                  <div class="menu-text">
                    <a
                      [routerLink]="
                        isRadiologist
                          ? '/study/profile'
                          : '/study/radiologist-profile'
                      "
                      >Profile</a
                    >
                  </div>
                </li>
                <hr class="hr-y" />
                <li class="layout-submenu-footer">
                  <button
                    type="button"
                    pButton
                    pRipple
                    icon="pi pi-sign-out"
                    iconPos="left"
                    label="Sign Out"
                    class="p-button-raised p-button-text"
                    (click)="logout()"
                  ></button>
                </li>
              </ul>
            </li>
          </ul>

          <ul class="profile-mobile-wrapper">
            <li>
              <span class="landing-home" (click)="alertForSetPreferredLandingPage()" title="Set the current page as Home">
                <i class="pi pi-home"></i>
              </span>
            </li>
            <li
              #mobileProfile
              class="topbar-item profile-item"
              [ngClass]="{
                'active-topmenuitem': appMain.activeTopbarItem === mobileProfile
              }"
            >
              <a
                href="#"
                (click)="appMain.onTopbarItemClick($event, mobileProfile)"
              >
                <span
                  class="profile-image-wrapper"
                  *ngIf="imageUrl != null; else elseBlockImg"
                >
                  <img [src]="imageUrl" alt="User Photo" />
                </span>
                <ng-template #elseBlockImg>
                  <span class="profile-image-wrapper">
                    <img src="{{ defaultProfilePhoto }}" alt="User Photo" />
                  </span>
                </ng-template>
                <span class="profile-info-wrapper">
                  <h3>{{ currentUserName }}</h3>
                </span>
              </a>
              <ul class="fadeInDown">
                <li class="layout-submenu-item">
                  <i class="pi pi-user icon icon-3"></i>
                  <div class="menu-text">
                    <a
                      [routerLink]="
                        isRadiologist
                          ? '/study/profile'
                          : '/study/radiologist-profile'
                      "
                      >Profile</a
                    >
                  </div>
                  <i class="pi pi-angle-right"></i>
                </li>
                <hr class="hr-y" />
                <li class="layout-submenu-footer">
                  <button
                    type="button"
                    pButton
                    pRipple
                    icon="pi pi-sign-out"
                    iconPos="left"
                    label="Sign Out"
                    class="p-button-raised p-button-text"
                    (click)="logout()"
                  ></button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <p-confirmDialog header="Confirmation" key="preferredLandingPageConfirm" icon="pi pi-exclamation-triangle"
      message="Are you sure you want to proceed?" [style]="{ width: '25vw' }" acceptButtonStyleClass="p-button-text"
      rejectButtonStyleClass="p-button-text">
    </p-confirmDialog>

    <div class="loading-overlay" *ngIf="loaderVisible">
      <div class="loader"></div>
    </div>
  `,
})
export class AppTopBarComponent implements OnInit, OnDestroy {
  client: User = new User();
  activeItem: number;
  currentUserName: string;
  currentUserProfilePhoto: string;
  defaultProfilePhoto: string;
  profileImagerUrl = environment.profileImagerUrl;
  loginUserOrgName: string;
  demoHospitalLogo: string;
  isRadiologist: boolean = false;
  isOrgImage: boolean = false;
  loaderVisible: boolean = false;
  imageUrl: string;
  landingPageRequest: User = new User();
  activeUrl: string;
  LandingPageName: string;
  private routerSubscription: Subscription;

  constructor(
    private _router: Router,
    public appMain: AppMainComponent,
    private _authService: AuthService,
    private _imageService: ImageService,
    private _alertify: AlertifyService,
    private _confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.getCurrentUserInfo();
    this.client = JSON.parse(localStorage.getItem("user"));
    this._imageService.getImageUrl().subscribe((url: string) => {
      this.imageUrl = url;
    });
  }

  mobileMegaMenuItemClick(index) {
    this.appMain.megaMenuMobileClick = true;
    this.activeItem = this.activeItem === index ? null : index;
  }

  getCurrentUserInfo() {
    let user = JSON.parse(localStorage.getItem("user")) as User;
    this.loginUserOrgName = user.OrgName;
    this.currentUserName = user.Fname + " " + user.Lname;
    if (user.ImgFileName != null && user.ImgFileName !== "") {
      this.defaultProfilePhoto =
        this.profileImagerUrl +
        "Profile/" +
        user.EmpId +
        "/" +
        user.ImgFileName;
    } else {
      this.defaultProfilePhoto = "./assets/layout/images/topbar/avatar.png";
    }

    this.demoHospitalLogo = "./assets/layout/images/topbar/demo-hospital.png";
    if (user.OrgImageString) {
      this.demoHospitalLogo = user.OrgImageString;
      this.isOrgImage = true;
    }
  
    if (user.JobType != "D") {
      this.isRadiologist = true;
    }
  }

  logout() {
    let user = JSON.parse(localStorage.getItem("user")) as User;
    let userId = +user.EmpId;

    this._authService.logout(userId).subscribe(
      (data) => {
        localStorage.removeItem("token");

        sessionStorage.removeItem("user");
        localStorage.removeItem("loginTime");

        this._router.navigate(["/login"]);
      },
      (error) => {
        // this.alertify.error('Failed to logout');
        // this.router.navigate(["/login"]);
      },
      () => {
        // this.alertify.success('Logged out');
        // this.router.navigate(["/login"]);
      }
    );
  }

  private getActiveUrl(): string {
    return this._router.url;  // Directly access the current URL from the Router service
  }

  private formatUrlSegment(url: string): string {
    const segments = url.split('/').filter(segment => segment); 
    const lastSegment = segments[segments.length - 1];
    const formattedSegment = lastSegment
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return formattedSegment;
  }

  setPreferredLandingPage() {
    this.loaderVisible = true;
    this.activeUrl = this.getActiveUrl();
    this.LandingPageName = this.formatUrlSegment(this.activeUrl);
    this.landingPageRequest.EmpId = this.client.EmpId;
    this.landingPageRequest.LandingPage = this.LandingPageName;
    this.landingPageRequest.LandingPageUrl = this.activeUrl;

    this._authService.setPreferredLandingPage(this.landingPageRequest).subscribe((res) => {
      if (res) {
        this.loaderVisible = false;
        this._alertify.success(`Successfully set the preferred landing page to ${this.LandingPageName}.`);
      }
    }, error => {
      this.loaderVisible = false;
        this._alertify.error("Something went wrong, please try again later.");
    });
  }
  

  alertForSetPreferredLandingPage() {
    this._confirmationService.confirm({
      key: "preferredLandingPageConfirm",
      message: "Do you want to set the current page as home?",
      accept: () => {
        this.setPreferredLandingPage();
      },
      reject: () => {
        this._alertify.message("You've rejected to exam.");
      },
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
