import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { GblCountry } from "../../_models/radiologist-signup/GblCountry";
import { GblDistrict } from "../../_models/radiologist-signup/GblDistrict";
import { GblProvince } from "../../_models/radiologist-signup/GblProvince";
import { GblSubdistrict } from "../../_models/radiologist-signup/GblSubdistrict";
import { User } from "../../_models/user-models/user";
import { ApiService } from "../api/api.service";

import { HttpClient } from "@angular/common/http";
import {
  GblPostalCode,
  GeneralResModel,
  HrEmpOrgMappingModel,
  HrEmpProfileModel,
  UserPreservedDataModel,
} from "src/app/_models";
import { Radiologist } from "src/app/_models/assignments/radiologist.model";
import { CommonResponse } from "src/app/_models/common-response.modle";
import { HrSubspecialty } from "src/app/_models/radiologist-signup/HrSubspecialty";
import { StudyModel } from "src/app/_models/study.model";
import { HrEmpdocument } from "src/app/_models/user-models/HrEmpdocument";
import { UserListViewModel } from "src/app/_models/user-models/UserListViewModel";
import { ResetPassword } from "src/app/_models/user-models/reset-password";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserService {
  photoUrl = new BehaviorSubject<string>("");
  private hospitalContract = new BehaviorSubject<boolean>(false);
  // private clickEvent = new BehaviorSubject<boolean>(false);
  currentPhotoUrl = this.photoUrl.asObservable();
  currenthospitalContract = this.hospitalContract.asObservable();
  private apiPATH = "User/";
  private uploadUrl = this.apiPATH + "/fileupload";
  private url: string;

  private baseURL = `${environment.apiUrl}User/`;

  constructor(private api: ApiService, private _httpClient: HttpClient) {}

  changehospitalContract(show: boolean) {
    this.hospitalContract.next(show);
  }

  getEmpOrgMapping(jobtypeId: number, orgId: number) {
    return this._httpClient.get<GeneralResModel<HrEmpOrgMappingModel[]>>(
      `${this.baseURL}GetEmpOrgMaping/${jobtypeId}/${orgId}`
    );
  }

  getUserMappingByOrgId(orgId: number, empId?: number) {
    let url = `${this.baseURL}GetUserMappingByOrgId/${orgId}`;
    if (empId !== undefined) {
      url += `/${empId}`;
    }
    return this._httpClient.get<UserListViewModel[]>(url);
  }

  getUserProfile(empId: number) {
    return this._httpClient.get<HrEmpProfileModel>(
      `${this.baseURL}getUserProfile/${empId}`
    );
  }

  updateRadInfo(request: FormData) {
    return this._httpClient.post<HrEmpProfileModel>(
      `${this.baseURL}addEmpProfile`,
      request,
      {
        responseType: "json",
      }
    );
  }

  insertUserPreservedData(request: UserPreservedDataModel) {
    return this.api.post<boolean>(
      `${this.apiPATH}insertUserPreservedData/`,
      request
    );
  }

  getUserPreservedData(userId: number, orgId: number, type: string) {
    return this.api.get<UserPreservedDataModel>(
      `${this.apiPATH}getUserPreservedData/${userId}/${orgId}/${type}`
    );
  }

  updateUserPassword(user: User): any {
    return this.api.put<User>(`${this.apiPATH}updateUserPassword`, user);
  }

  updateUser(user: User) {
    return this.api.post<User>(`${this.apiPATH}updateUser/`, user);
  }

  approveUser(userId: number) {
    return this.api.put<boolean>(`${this.apiPATH}approveUser/${userId}`, null);
  }

  denyUser(user: User) {
    return this.api.post<boolean>(`${this.apiPATH}denyUser`, user);
  }

  getUserById(userId: number, isCloud: boolean): any {
    return this.api.get<User>(
      `${this.apiPATH}getUserById/${userId}/${isCloud}`
    );
  }

  getUserList() {
    return this.api.get<User[]>(`${this.apiPATH}users`);
  }

  getUserListByOrgId(orgId) {
    return this.api.get<User[]>(`${this.apiPATH}usersByOrgId/${orgId}`);
  }

  getRadiologistByOrgId(orgId: number) {
    return this.api.get<User[]>(
      `${this.apiPATH}GetRadiologistByOrgId/${orgId}`
    );
  }

  getUserAfterVerification(orgId) {
    return this.api.get<UserListViewModel[]>(
      `${this.apiPATH}getUserAfterVerification/${orgId}`
    );
  }

  getRadiologistListByOrgId(orgId, model: StudyModel) {
    return this.api.post<Radiologist[]>(
      `${this.apiPATH}GetadiologistByOrgId/${orgId}`,
      model
    );
  }
  getRadiologistListByGovExamId(orgId, model: StudyModel) {
    return this.api.post<Radiologist[]>(
      `${this.apiPATH}GetadiologistByGovExam/${orgId}`,
      model
    );
  }
  fileUpload(input: any) {
    return this.api.post<any>(`${this.apiPATH}fileupload`, input);
  }

  changeMemberPhoto(photoUrl: string) {
    this.photoUrl.next(photoUrl);
  }

  isExistEmail(email: string): Observable<boolean> {
    return this.api.get<boolean>(`${this.apiPATH}isExistingUserEmail/${email}`);
  }

  // save User information in HR_Emp Table
  addNewUser(user: FormData) {
    try {
      return this.api.post<User>(`${this.apiPATH}AddNewUser`, user);
    } catch (error) {
      throw error;
    }
  }

  updateUserExperience(aUser: User) {
    return this.api.post<User>(`${this.apiPATH}updateUserExperience`, aUser);
  }

  getUserDetails() {
    return this.api.get<User>(`${this.apiPATH}getUserDetails/`);
  }

  getUserByLineId(lineId: string) {
    return this.api.get<User>(`${this.apiPATH}users/${lineId}`);
  }

  addNewRadiologist(user: FormData) {
    // return this.api.post<User>(`${this.apiPATH}addNewRadiologist`, user );

    return this._httpClient.post<User>(
      `${this.baseURL}addNewRadiologist`,
      user,
      {
        responseType: "json",
      }
    );
  }

  getCountryList() {
    return this.api.get<GblCountry[]>(`${this.apiPATH}countries`);
  }

  getAddressList() {
    return this.api.get<CommonResponse>(`${this.apiPATH}GetAddressList`);
  }

  getProvinceList(CountryId: number) {
    return this.api.get<GblProvince[]>(`${this.apiPATH}Provinces/${CountryId}`);
  }

  getDistrictList(provinceId: number) {
    return this.api.get<GblDistrict[]>(
      `${this.apiPATH}Districts/${provinceId}`
    );
  }

  getSubDistrictList(districtId: number) {
    return this.api.get<GblSubdistrict[]>(
      `${this.apiPATH}SubDistricts/${districtId}`
    );
  }

  getPostalCodesByDistricId(districtId: number) {
    return this.api.get<GeneralResModel<GblPostalCode[]>>(
      `${this.apiPATH}GetPostalCodesByDistricId/${districtId}`
    );
  }

  deactivateRadiologistContact(request: UserListViewModel) {
    return this.api.post<GeneralResModel<UserListViewModel>>(
      `${this.apiPATH}InActiveEmpMappOrg`,
      request
    );
  }

  approveEmpMappOrg(user: UserListViewModel) {
    try {
      return this.api.post<CommonResponse>(
        `${this.apiPATH}ApproveEmpMappOrg`,
        user
      );
    } catch (error) {
      throw error;
    }
  }

  addEmpMapping(user: HrEmpOrgMappingModel) {
    try {
      return this.api.post<CommonResponse>(
        `${this.apiPATH}AddEmpMapping`,
        user
      );
    } catch (error) {
      throw error;
    }
  }

  getSubSpecialtyList() {
    return this.api.get<HrSubspecialty[]>(`${this.apiPATH}SubSpecialty`);
  }

  getUserDocumentList(userId: number) {
    return this.api.get<HrEmpdocument[]>(
      `${this.apiPATH}getUserDocumentList/${userId}`
    );
  }

  emailAlreayExists(email: string) {
    return this.api.get<boolean>(`${this.apiPATH}emailAlreayExists/${email}`);
  }

  phoneAlreayExists(phone: string, countryDialCode: string) {
    let phoneValidation = {
      Phone: phone,
      CountryDialCode: countryDialCode,
    };

    return this.api.post<boolean>(
      `${this.apiPATH}phoneAlreayExists`,
      phoneValidation
    );
  }

  downloadUserDocument(empId: number) {
    return this._httpClient.post(
      `${this.baseURL}downloadUserDocument/${empId}`,
      {}
    );
  }

  requestForResetPasswordOTP(resetPass: ResetPassword) {
    return this.api.post<number>(
      `${this.apiPATH}requstResetPassword`,
      resetPass
    );
  }

  VerifyOTP(resetPass: ResetPassword) {
    return this.api.post<User>(`${this.apiPATH}verifyOTP`, resetPass);
  }

  changeRadiologistPassword(user: User): Observable<boolean> {
    return this.api.post<boolean>(
      `${this.apiPATH}changeRadiologistPassword`,
      user
    );
  }

  getAllOrgListByOrgId(orgId: number) {
    return this.api.get<CommonResponse>(
      `${this.apiPATH}getAllOrgListByOrgId/${orgId}`
    );
  }

  getGblActivePackages() {
    return this.api.get<CommonResponse>(`${this.apiPATH}GetGblActivePackage`);
  }
}
