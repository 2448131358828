<div class="singup-top grid">
  <div class="col-12 md:col-4">
    <div class="signup-logo">
      <img src="assets/layout/images/logo/logo.png" alt="mirage-layout" height="24" />
    </div>
  </div>
  <div class="col-12 md:col-8 text-right">
    <ul>
      <li><a href="https://radportal.net/">HOME</a></li>
      <li><a href="https://radportal.net/#about">ABOUT</a></li>
      <li><a href="https://radportal.net/#contact">CONTACT US</a></li>
    </ul>
  </div>
</div>

<div class="singup">
  <p-card styleClass="patient-information-panel">
    <form [formGroup]="resetForm">
      <div class="p-fluid grid">
        <div class="col-12 md:col-4"></div>
        <div class="col-12 md:col-4" *ngIf="isVerifySuccessfull == false">
          <div class="field grid relative check-mark">
            <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Email</label>
            <div class="col-12 md:col-9">
              <input (keyup)="checkEmailOrPhoneNumber($event)" formControlName="EmailOrPhone" type="text" pInputText
                [(ngModel)]="selectedEmailOrPhoneNumber" placeholder="Email address" />
            </div>

            <p-message severity="error" *ngIf="
                emailExists == false &&
                !isEmailValid() &&
                resetForm.controls['EmailOrPhone'].dirty
              " class="danger exist-email" text="User not found"></p-message>
          </div>
        </div>
        <div class="col-12 md:col-4"></div>
      </div>

      <div class="otp-container grid">
        <div class="otp-email field" *ngIf="isVerifySuccessfull == false && isSendOtp == true">
          <label htmlfor="lastname">Type Code</label>
          <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{ length: 6 }"></ng-otp-input>
        </div>
      </div>

      <div class="p-fluid grid" *ngIf="isVerifySuccessfull == true">
        <div class="col-12 md:col-3"></div>
        <div class="col-12 md:col-5">
          <div class="field grid relative pass-eye">
            <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Password</label>
            <div class="col-12 md:col-9">
              <div class="pwd-input-group">
                <input type="text" pInputText [(ngModel)]="selectedPassword" formControlName="Password"
                  pTooltip={{validationMessageForTT}} tooltipEvent="focus" (keyup)="validatePassword($event)"
                  [class.password-mask]="isPasswordMasked" />
                <button pButton type="button" icon="pi pi-eye" (click)="togglePasswordMask()"
                  class="transparent-button"></button>
              </div>
              <!-- <p-password [(ngModel)]="selectedPassword" formControlName="Password" [toggleMask]="true"></p-password> -->
            </div>
          </div>

          <div class="field grid relative pass-eye">
            <label htmlfor="lastname" class="col-12 mb-2 md:col-3 md:mb-0">Confirm Password</label>
            <div class="col-12 md:col-9">
              <div class="pwd-input-group">
                <input type="text" pInputText [(ngModel)]="selectedConfirmPassword" formControlName="ConfirmPassword"
                  [class.password-mask]="isPasswordMasked" />
                <button pButton type="button" icon="pi pi-eye" (click)="togglePasswordMask()"
                  class="transparent-button"></button>
              </div>
              <!-- <p-password [(ngModel)]="selectedConfirmPassword" formControlName="ConfirmPassword"
                [toggleMask]="true"></p-password> -->
            </div>

            <div *ngIf="
                resetForm.controls['ConfirmPassword'].dirty &&
                resetForm.hasError('mismatch')
              ">
              Password mismatch
            </div>
          </div>
        </div>

        <!-- <div  *ngIf="IsSentVerificationCode==true">
        <p> 
          <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:6}"></ng-otp-input>
        </p>
    
        <p>
        
      
        <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:6}"></ng-otp-input>
    
      </p>
    </div> -->
      </div>
    </form>
  </p-card>
</div>

<div class="confirm-second-step mt-50">
  <div class="grid verify-btn" *ngIf="isSendOtp == false">
    <div class="col-12 md:col-5"></div>
    <div class="verify-btn">
      <p-button icon="pi pi-send" [disabled]="isEmailValid() || emailExists == false" label="Send Verification Code"
        (onClick)="sentVerificationCode()"></p-button>
    </div>
  </div>

  <div class="grid verify-btn" *ngIf="isTimeOut == true">
    <div class="col-12 md:col-5"></div>
    <div class="verify-btn">
      <p-button icon="pi pi-send" label="Resend Verification Code" (onClick)="sentVerificationCode()"></p-button>
    </div>
  </div>

  <div class="grid verify-btn" *ngIf="isVerifySuccessfull == false && isSendOtp == true">
    <div class="col-12 md:col-5"></div>
    <div class="verify-btn">
      <p-button icon="fa fa-check-square-o" label="Confirm" (onClick)="VerifyOtp()"></p-button>
    </div>
  </div>

  <div class="grid verify-btn" *ngIf="isVerifySuccessfull == true && isSendOtp == true">
    <div class="col-12 md:col-5"></div>
    <div class="verify-btn">
      <p-button icon="pi pi-save" [disabled]="
          selectedPassword != selectedConfirmPassword ||
          selectedPassword == null
        " label="Update Password" (onClick)="UpdatePassword()"></p-button>
    </div>
  </div>
</div>

<div *ngIf="isLoaderVisible" class="loading-overlay">
  <div class="loader"></div>
</div>