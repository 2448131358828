import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { NgOtpInputComponent } from "ng-otp-input";
import { ConfirmationService } from "primeng/api";
import { environment } from "src/environments/environment";
import { GeneralResModel } from "../_models";
import { User } from "../_models/user-models/user";
import { DataService } from "../_services";
import { AuthService } from "../_services/auth/auth.service";
import { AlertifyService } from "../_services/common/alertify.service";
import { AppSessionState } from "../_services/common/app.service";
import { login } from "../_store/actions/login.actions";
import { UserSessionStateModel } from "../_store/models/user-session-state.model";

@Component({
  selector: "app-two-factor-auth-verify",
  templateUrl: "./two-factor-auth-verify.component.html",
  styleUrls: ["./two-factor-auth-verify.component.scss"],
})
export class TwoFactorAuthVerifyComponent implements OnInit {
  client: User;
  dark: boolean;
  loginModel: any = {};
  TotpVerifyCode: string;
  isCloud: boolean = true;
  isDisabled: boolean = false;
  selectedTwoFaCode: string = "";
  twoFAVerifyrequest: User = new User();
  selectedTwoFaCodeForBackup: string = "";
  isVisibleSigninRecoverCodePanel: boolean = false;
  @ViewChild("otpInputForInstantCode")
  otpInputForInstantCode: NgOtpInputComponent;
  @ViewChild("otpInputForRecoverCode")
  otpInputForRecoverCode: NgOtpInputComponent;

  constructor(
    private _router: Router,
    private _store: Store<any>,
    private _dataService: DataService,
    private _authService: AuthService,
    private _alertify: AlertifyService,
    private _appSessionState: AppSessionState,
    private _confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    localStorage.clear();
  }

  onTwoFaCodeChange(code) {
    this.selectedTwoFaCode = code;
  }

  onTwoFaCodeChangeForBackup(backupCode) {
    this.selectedTwoFaCodeForBackup = backupCode;
  }

  verifyCode() {
    this.login();
  }

  verifyForRecoverCode() {
    this.login();
  }

  selectedTwoFaCodeValid(x): boolean {
    if (x === "backupCode") {
      return this.selectedTwoFaCodeForBackup.length === 6;
    } else {
      return this.selectedTwoFaCode.length === 6;
    }
  }

  cancelSigningIn() {
    this.routeToLoginScreen();
  }

  signinWithRecoverCode() {
    this.isVisibleSigninRecoverCodePanel = true;
  }

  private routeToStudyWorklist() {
    this._router.navigate(["/study/worklist"]);
  }

  private routeToLoginScreen() {
    this._router.navigate(["/login"]);
  }

  closeSigninRecoverCodePanle() {
    this.resetOtpInput();
    this.isVisibleSigninRecoverCodePanel = false;
  }

  private resetOtpInput() {
    if (this.selectedTwoFaCodeForBackup != null) {
      this.otpInputForRecoverCode.setValue("");
    } else if (this.selectedTwoFaCode != null) {
      this.otpInputForInstantCode.setValue("");
    } else {
      return;
    }
  }

  private login() {
    this._authService.login(this._dataService.sharedLoginReqData).subscribe(
      (data) => {
        if (data) {
          if (data.user.IsSuperAdmin) {
            environment.isSuperAdmin = true;
          }

          if (data.user.JobType == "A") {
            environment.isSiteAdmin = true;
          }

          this._appSessionState.setToken(
            data.tokenString,
            <User>data.userRights,
            "" + new Date()
          );

          this._appSessionState.setUserToSessionStorage(data.user);
          localStorage.setItem("immediateReport", "true");
          this.setUserSessionState(data);

          this.client = JSON.parse(localStorage.getItem("user"));

          if (this.client != null) {
            if (
              this.selectedTwoFaCode === "" ||
              this.selectedTwoFaCode === null
            ) {
              this.twoFAVerifyrequest = this.client;
              this.twoFAVerifyrequest.TotpVerifyCode =
                this.selectedTwoFaCodeForBackup;
              this._authService
                .user2faAuthBackupCodesVerify(this.twoFAVerifyrequest)
                .subscribe((res: GeneralResModel<User>) => {
                  if (res.IsSucceed) {
                    this.closeSigninRecoverCodePanle();
                    this.twoFAVerifyrequest = new User();

                    this._confirmationService.confirm({
                      key: "confirmTwoFADisable",
                      message: "Do you want to disable 2FA on your Account?",
                      accept: () => {
                        this._authService
                          .user2faAuthDisable(this.client.EmpId)
                          .subscribe((res: GeneralResModel<string>) => {
                            if (res.IsSucceed) {
                              this._alertify.success(
                                "2FA has been successfully disabled from your profile."
                              );

                              this.routeToStudyWorklist();
                              this._alertify.success("Logged in successfully.");
                            } else {
                              this._alertify.error(res.Message);
                            }
                          });
                      },
                      reject: () => {
                        this.routeToStudyWorklist();
                        this._alertify.success("Logged in successfully.");
                        return;
                      },
                    });
                  } else {
                    this.twoFAVerifyrequest = new User();
                    this._alertify.error(res.Message);
                  }
                });
            } else {
              this.twoFAVerifyrequest = this.client;
              this.twoFAVerifyrequest.TotpVerifyCode = this.selectedTwoFaCode;
              this._authService
                .getUser2faAuthVerifyingTOTP(this.twoFAVerifyrequest)
                .subscribe((res: boolean) => {
                  if (res) {
                    this.twoFAVerifyrequest = new User();
                    this.routeToStudyWorklist();
                    this._alertify.success("Logged in successfully.");
                  } else {
                    this.twoFAVerifyrequest = new User();
                    this._alertify.error(
                      "Please enter valid code within 30 seconds."
                    );
                  }
                });
            }
          }
        }
      },
      (error) => {
        this._alertify.error("Failed to login");
      }
    );
  }

  private setUserSessionState(data) {
    let loginModel: UserSessionStateModel = {
      LoginTime: new Date(),
      UserInfo: data.user,
    };
    this._store.dispatch(login({ userSessionDetail: loginModel }));
  }
}
