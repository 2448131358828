import { HttpClient } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MenuItem } from "primeng/api";
import { Subject, debounceTime, distinctUntilChanged, switchMap } from "rxjs";
import { emailValidator } from "src/app/_helper/validator.helper";
import {
  GblPostalCode,
  GeneralResModel,
  OrderDocumentTypeResModel,
} from "src/app/_models";
import { GblCountry } from "src/app/_models/radiologist-signup/GblCountry";
import { HrEmpVerify } from "src/app/_models/radiologist-signup/HrEmpVerify";
import { SiteInformation } from "src/app/_models/site-information.model";
import { HrEmpdocument } from "src/app/_models/user-models/HrEmpdocument";
import { SiteService } from "src/app/_services/site/site.services";
import { UserVerifyService } from "src/app/_services/user/user-verify.service";
import { environment } from "src/environments/environment";
import { GblDistrict } from "../../../../_models/radiologist-signup/GblDistrict";
import { GblProvince } from "../../../../_models/radiologist-signup/GblProvince";
import { GblSubdistrict } from "../../../../_models/radiologist-signup/GblSubdistrict";
import { HrSubspecialty } from "../../../../_models/radiologist-signup/HrSubspecialty";
import { User } from "../../../../_models/user-models/user";
import { AlertifyService } from "../../../../_services/common/alertify.service";
import { UserService } from "../../../../_services/user/user.service";
import { RadiologistPhoneVerificationComponent } from "../radiologist-phone-verification/radiologist-phone-verification.component";

@Component({
  selector: "app-radiologist-signup-two",
  templateUrl: "./radiologist-signup-two.component.html",
  styleUrls: ["./radiologist-signup-two.component.scss"],
})
export class RadiologistSignupTwoComponent implements OnInit {
  radiogistForm: FormGroup;
  IsEmailVerified: boolean;
  IsPhoneVerified: boolean;
  selectedEmailOTP: any;
  selectedPhoneOtp: any;
  ProfileImageToUpload: any;
  uploadUrl: string;
  uploadedFiles: any[] = [];
  displayDocumentUploadDialog: boolean;
  documentFile: any;
  newUser: User;
  selecetdInput: any;
  emailExists: boolean;
  PhoneExists: boolean;
  CountryWithCode: { name: string; dial_code: string; code: string }[];

  siteList: SiteInformation[] = [];
  selectedSite: SiteInformation[] = [];

  constructor(
    private _activateRoute: ActivatedRoute,
    private _userService: UserService,
    private _siteService: SiteService,
    private alertifyService: AlertifyService,
    private userVerifyService: UserVerifyService,
    private httpClient: HttpClient,
    private router: Router
  ) {}

  lineProfileImageUrl: string;

  genders: any;
  orglist: any;
  examList: any;
  selectedFName: string;
  selectedLName: string;
  selectedEmail: string;
  selectePassword: string;
  selectConfirmPassword: string;
  selectedLineId: string;
  selectedPhoneNo: string;
  //selectedZipCode?: number;
  profileImageUrl: string;
  selectedCountry: GblCountry;
  countryList: GblCountry[];
  selectedDistrict: GblDistrict;
  districtList: GblDistrict[];
  selectedProvince: GblProvince;
  ProvinceList: GblProvince[];
  selectedSubDistrict: GblSubdistrict;
  SubDistrictList: GblSubdistrict[];
  postalCodeList: GblPostalCode[];
  selectedZipCode: GblPostalCode;

  selectedPassword: string;
  selectedConfirmPassword: string;
  disable: boolean = true;
  saveOrUpdateButtonLabel: string;

  items: MenuItem[];

  activeIndex: number = 0;

  selectedHrUser: User;
  IsVerifySuccessfull: boolean = false;
  IsSentVerificationCode: boolean = false;

  @ViewChild(RadiologistPhoneVerificationComponent)
  radiologistPhoneVerificationComponent: RadiologistPhoneVerificationComponent;

  @Input("LineId") LineId: string;

  @Output() userEmitter = new EventEmitter<User>();

  VerificationId: number;
  SubSpecialtyList: HrSubspecialty[];
  selectedSubSpecialty: HrSubspecialty[] = [];

  documentTypedDopdownItems: OrderDocumentTypeResModel[] = [];
  selectedDocumentType: OrderDocumentTypeResModel;
  userDocumet: HrEmpdocument = new HrEmpdocument();
  selectedColumns: any[];
  index: number = 0;
  timerInterval: any;
  display: any;
  IsCountDownTimeOut: boolean;
  searchEmailTextSubject$ = new Subject<{ email: string; Id: number }>();
  searchPhoneTextSubject$ = new Subject<{
    phone: string;
    Id: number;
    countryDialCode: string;
  }>();
  IsSentResentPassword: boolean = false;
  showOrHideResendButton: boolean = false;
  isRadiographer: boolean = false;
  registrationHeader: string = "Sign up as A Radiologist";
  selectedTermAndCon: boolean = false;

  @ViewChild("ngOtpInputEmail") ngOtpInputRefEmail: any;
  @ViewChild("ngOtpInputPhone") ngOtpInputRefPhone: any;
  selectedPhoneCode: any;
  isLoaderVisible: boolean = false;
  selectedMedicalLincence: string;
  selectedRefDocTelNo: string;
  selectedRefDocName: string;
  ngOnInit() {
    //this.getCustometHospitalList();
    this.getFilterHospitalList("Imaging");

    this.CountryWithCode = [
      {
        name: "Afghanistan",
        dial_code: "+93",
        code: "AF",
      },
      {
        name: "Aland Islands",
        dial_code: "+358",
        code: "AX",
      },
      {
        name: "Albania",
        dial_code: "+355",
        code: "AL",
      },
      {
        name: "Algeria",
        dial_code: "+213",
        code: "DZ",
      },
      {
        name: "AmericanSamoa",
        dial_code: "+1684",
        code: "AS",
      },
      {
        name: "Andorra",
        dial_code: "+376",
        code: "AD",
      },
      {
        name: "Angola",
        dial_code: "+244",
        code: "AO",
      },
      {
        name: "Anguilla",
        dial_code: "+1264",
        code: "AI",
      },
      {
        name: "Antarctica",
        dial_code: "+672",
        code: "AQ",
      },
      {
        name: "Antigua and Barbuda",
        dial_code: "+1268",
        code: "AG",
      },
      {
        name: "Argentina",
        dial_code: "+54",
        code: "AR",
      },
      {
        name: "Armenia",
        dial_code: "+374",
        code: "AM",
      },
      {
        name: "Aruba",
        dial_code: "+297",
        code: "AW",
      },
      {
        name: "Australia",
        dial_code: "+61",
        code: "AU",
      },
      {
        name: "Austria",
        dial_code: "+43",
        code: "AT",
      },
      {
        name: "Azerbaijan",
        dial_code: "+994",
        code: "AZ",
      },
      {
        name: "Bahamas",
        dial_code: "+1242",
        code: "BS",
      },
      {
        name: "Bahrain",
        dial_code: "+973",
        code: "BH",
      },
      {
        name: "Bangladesh",
        dial_code: "+880",
        code: "BD",
      },
      {
        name: "Barbados",
        dial_code: "+1246",
        code: "BB",
      },
      {
        name: "Belarus",
        dial_code: "+375",
        code: "BY",
      },
      {
        name: "Belgium",
        dial_code: "+32",
        code: "BE",
      },
      {
        name: "Belize",
        dial_code: "+501",
        code: "BZ",
      },
      {
        name: "Benin",
        dial_code: "+229",
        code: "BJ",
      },
      {
        name: "Bermuda",
        dial_code: "+1441",
        code: "BM",
      },
      {
        name: "Bhutan",
        dial_code: "+975",
        code: "BT",
      },
      {
        name: "Bolivia, Plurinational State of",
        dial_code: "+591",
        code: "BO",
      },
      {
        name: "Bosnia and Herzegovina",
        dial_code: "+387",
        code: "BA",
      },
      {
        name: "Botswana",
        dial_code: "+267",
        code: "BW",
      },
      {
        name: "Brazil",
        dial_code: "+55",
        code: "BR",
      },
      {
        name: "British Indian Ocean Territory",
        dial_code: "+246",
        code: "IO",
      },
      {
        name: "Brunei Darussalam",
        dial_code: "+673",
        code: "BN",
      },
      {
        name: "Bulgaria",
        dial_code: "+359",
        code: "BG",
      },
      {
        name: "Burkina Faso",
        dial_code: "+226",
        code: "BF",
      },
      {
        name: "Burundi",
        dial_code: "+257",
        code: "BI",
      },
      {
        name: "Cambodia",
        dial_code: "+855",
        code: "KH",
      },
      {
        name: "Cameroon",
        dial_code: "+237",
        code: "CM",
      },
      {
        name: "Canada",
        dial_code: "+1",
        code: "CA",
      },
      {
        name: "Cape Verde",
        dial_code: "+238",
        code: "CV",
      },
      {
        name: "Cayman Islands",
        dial_code: "+ 345",
        code: "KY",
      },
      {
        name: "Central African Republic",
        dial_code: "+236",
        code: "CF",
      },
      {
        name: "Chad",
        dial_code: "+235",
        code: "TD",
      },
      {
        name: "Chile",
        dial_code: "+56",
        code: "CL",
      },
      {
        name: "China",
        dial_code: "+86",
        code: "CN",
      },
      {
        name: "Christmas Island",
        dial_code: "+61",
        code: "CX",
      },
      {
        name: "Cocos (Keeling) Islands",
        dial_code: "+61",
        code: "CC",
      },
      {
        name: "Colombia",
        dial_code: "+57",
        code: "CO",
      },
      {
        name: "Comoros",
        dial_code: "+269",
        code: "KM",
      },
      {
        name: "Congo",
        dial_code: "+242",
        code: "CG",
      },
      {
        name: "Congo, The Democratic Republic of the Congo",
        dial_code: "+243",
        code: "CD",
      },
      {
        name: "Cook Islands",
        dial_code: "+682",
        code: "CK",
      },
      {
        name: "Costa Rica",
        dial_code: "+506",
        code: "CR",
      },
      {
        name: "Cote d'Ivoire",
        dial_code: "+225",
        code: "CI",
      },
      {
        name: "Croatia",
        dial_code: "+385",
        code: "HR",
      },
      {
        name: "Cuba",
        dial_code: "+53",
        code: "CU",
      },
      {
        name: "Cyprus",
        dial_code: "+357",
        code: "CY",
      },
      {
        name: "Czech Republic",
        dial_code: "+420",
        code: "CZ",
      },
      {
        name: "Denmark",
        dial_code: "+45",
        code: "DK",
      },
      {
        name: "Djibouti",
        dial_code: "+253",
        code: "DJ",
      },
      {
        name: "Dominica",
        dial_code: "+1767",
        code: "DM",
      },
      {
        name: "Dominican Republic",
        dial_code: "+1849",
        code: "DO",
      },
      {
        name: "Ecuador",
        dial_code: "+593",
        code: "EC",
      },
      {
        name: "Egypt",
        dial_code: "+20",
        code: "EG",
      },
      {
        name: "El Salvador",
        dial_code: "+503",
        code: "SV",
      },
      {
        name: "Equatorial Guinea",
        dial_code: "+240",
        code: "GQ",
      },
      {
        name: "Eritrea",
        dial_code: "+291",
        code: "ER",
      },
      {
        name: "Estonia",
        dial_code: "+372",
        code: "EE",
      },
      {
        name: "Ethiopia",
        dial_code: "+251",
        code: "ET",
      },
      {
        name: "Falkland Islands (Malvinas)",
        dial_code: "+500",
        code: "FK",
      },
      {
        name: "Faroe Islands",
        dial_code: "+298",
        code: "FO",
      },
      {
        name: "Fiji",
        dial_code: "+679",
        code: "FJ",
      },
      {
        name: "Finland",
        dial_code: "+358",
        code: "FI",
      },
      {
        name: "France",
        dial_code: "+33",
        code: "FR",
      },
      {
        name: "French Guiana",
        dial_code: "+594",
        code: "GF",
      },
      {
        name: "French Polynesia",
        dial_code: "+689",
        code: "PF",
      },
      {
        name: "Gabon",
        dial_code: "+241",
        code: "GA",
      },
      {
        name: "Gambia",
        dial_code: "+220",
        code: "GM",
      },
      {
        name: "Georgia",
        dial_code: "+995",
        code: "GE",
      },
      {
        name: "Germany",
        dial_code: "+49",
        code: "DE",
      },
      {
        name: "Ghana",
        dial_code: "+233",
        code: "GH",
      },
      {
        name: "Gibraltar",
        dial_code: "+350",
        code: "GI",
      },
      {
        name: "Greece",
        dial_code: "+30",
        code: "GR",
      },
      {
        name: "Greenland",
        dial_code: "+299",
        code: "GL",
      },
      {
        name: "Grenada",
        dial_code: "+1473",
        code: "GD",
      },
      {
        name: "Guadeloupe",
        dial_code: "+590",
        code: "GP",
      },
      {
        name: "Guam",
        dial_code: "+1671",
        code: "GU",
      },
      {
        name: "Guatemala",
        dial_code: "+502",
        code: "GT",
      },
      {
        name: "Guernsey",
        dial_code: "+44",
        code: "GG",
      },
      {
        name: "Guinea",
        dial_code: "+224",
        code: "GN",
      },
      {
        name: "Guinea-Bissau",
        dial_code: "+245",
        code: "GW",
      },
      {
        name: "Guyana",
        dial_code: "+595",
        code: "GY",
      },
      {
        name: "Haiti",
        dial_code: "+509",
        code: "HT",
      },
      {
        name: "Holy See (Vatican City State)",
        dial_code: "+379",
        code: "VA",
      },
      {
        name: "Honduras",
        dial_code: "+504",
        code: "HN",
      },
      {
        name: "Hong Kong",
        dial_code: "+852",
        code: "HK",
      },
      {
        name: "Hungary",
        dial_code: "+36",
        code: "HU",
      },
      {
        name: "Iceland",
        dial_code: "+354",
        code: "IS",
      },
      {
        name: "India",
        dial_code: "+91",
        code: "IN",
      },
      {
        name: "Indonesia",
        dial_code: "+62",
        code: "ID",
      },
      {
        name: "Iran, Islamic Republic of Persian Gulf",
        dial_code: "+98",
        code: "IR",
      },
      {
        name: "Iraq",
        dial_code: "+964",
        code: "IQ",
      },
      {
        name: "Ireland",
        dial_code: "+353",
        code: "IE",
      },
      {
        name: "Isle of Man",
        dial_code: "+44",
        code: "IM",
      },
      {
        name: "Israel",
        dial_code: "+972",
        code: "IL",
      },
      {
        name: "Italy",
        dial_code: "+39",
        code: "IT",
      },
      {
        name: "Jamaica",
        dial_code: "+1876",
        code: "JM",
      },
      {
        name: "Japan",
        dial_code: "+81",
        code: "JP",
      },
      {
        name: "Jersey",
        dial_code: "+44",
        code: "JE",
      },
      {
        name: "Jordan",
        dial_code: "+962",
        code: "JO",
      },
      {
        name: "Kazakhstan",
        dial_code: "+77",
        code: "KZ",
      },
      {
        name: "Kenya",
        dial_code: "+254",
        code: "KE",
      },
      {
        name: "Kiribati",
        dial_code: "+686",
        code: "KI",
      },
      {
        name: "Korea, Democratic People's Republic of Korea",
        dial_code: "+850",
        code: "KP",
      },
      {
        name: "Korea, Republic of South Korea",
        dial_code: "+82",
        code: "KR",
      },
      {
        name: "Kuwait",
        dial_code: "+965",
        code: "KW",
      },
      {
        name: "Kyrgyzstan",
        dial_code: "+996",
        code: "KG",
      },
      {
        name: "Laos",
        dial_code: "+856",
        code: "LA",
      },
      {
        name: "Latvia",
        dial_code: "+371",
        code: "LV",
      },
      {
        name: "Lebanon",
        dial_code: "+961",
        code: "LB",
      },
      {
        name: "Lesotho",
        dial_code: "+266",
        code: "LS",
      },
      {
        name: "Liberia",
        dial_code: "+231",
        code: "LR",
      },
      {
        name: "Libyan Arab Jamahiriya",
        dial_code: "+218",
        code: "LY",
      },
      {
        name: "Liechtenstein",
        dial_code: "+423",
        code: "LI",
      },
      {
        name: "Lithuania",
        dial_code: "+370",
        code: "LT",
      },
      {
        name: "Luxembourg",
        dial_code: "+352",
        code: "LU",
      },
      {
        name: "Macao",
        dial_code: "+853",
        code: "MO",
      },
      {
        name: "Macedonia",
        dial_code: "+389",
        code: "MK",
      },
      {
        name: "Madagascar",
        dial_code: "+261",
        code: "MG",
      },
      {
        name: "Malawi",
        dial_code: "+265",
        code: "MW",
      },
      {
        name: "Malaysia",
        dial_code: "+60",
        code: "MY",
      },
      {
        name: "Maldives",
        dial_code: "+960",
        code: "MV",
      },
      {
        name: "Mali",
        dial_code: "+223",
        code: "ML",
      },
      {
        name: "Malta",
        dial_code: "+356",
        code: "MT",
      },
      {
        name: "Marshall Islands",
        dial_code: "+692",
        code: "MH",
      },
      {
        name: "Martinique",
        dial_code: "+596",
        code: "MQ",
      },
      {
        name: "Mauritania",
        dial_code: "+222",
        code: "MR",
      },
      {
        name: "Mauritius",
        dial_code: "+230",
        code: "MU",
      },
      {
        name: "Mayotte",
        dial_code: "+262",
        code: "YT",
      },
      {
        name: "Mexico",
        dial_code: "+52",
        code: "MX",
      },
      {
        name: "Micronesia, Federated States of Micronesia",
        dial_code: "+691",
        code: "FM",
      },
      {
        name: "Moldova",
        dial_code: "+373",
        code: "MD",
      },
      {
        name: "Monaco",
        dial_code: "+377",
        code: "MC",
      },
      {
        name: "Mongolia",
        dial_code: "+976",
        code: "MN",
      },
      {
        name: "Montenegro",
        dial_code: "+382",
        code: "ME",
      },
      {
        name: "Montserrat",
        dial_code: "+1664",
        code: "MS",
      },
      {
        name: "Morocco",
        dial_code: "+212",
        code: "MA",
      },
      {
        name: "Mozambique",
        dial_code: "+258",
        code: "MZ",
      },
      {
        name: "Myanmar",
        dial_code: "+95",
        code: "MM",
      },
      {
        name: "Namibia",
        dial_code: "+264",
        code: "NA",
      },
      {
        name: "Nauru",
        dial_code: "+674",
        code: "NR",
      },
      {
        name: "Nepal",
        dial_code: "+977",
        code: "NP",
      },
      {
        name: "Netherlands",
        dial_code: "+31",
        code: "NL",
      },
      {
        name: "Netherlands Antilles",
        dial_code: "+599",
        code: "AN",
      },
      {
        name: "New Caledonia",
        dial_code: "+687",
        code: "NC",
      },
      {
        name: "New Zealand",
        dial_code: "+64",
        code: "NZ",
      },
      {
        name: "Nicaragua",
        dial_code: "+505",
        code: "NI",
      },
      {
        name: "Niger",
        dial_code: "+227",
        code: "NE",
      },
      {
        name: "Nigeria",
        dial_code: "+234",
        code: "NG",
      },
      {
        name: "Niue",
        dial_code: "+683",
        code: "NU",
      },
      {
        name: "Norfolk Island",
        dial_code: "+672",
        code: "NF",
      },
      {
        name: "Northern Mariana Islands",
        dial_code: "+1670",
        code: "MP",
      },
      {
        name: "Norway",
        dial_code: "+47",
        code: "NO",
      },
      {
        name: "Oman",
        dial_code: "+968",
        code: "OM",
      },
      {
        name: "Pakistan",
        dial_code: "+92",
        code: "PK",
      },
      {
        name: "Palau",
        dial_code: "+680",
        code: "PW",
      },
      {
        name: "Palestinian Territory, Occupied",
        dial_code: "+970",
        code: "PS",
      },
      {
        name: "Panama",
        dial_code: "+507",
        code: "PA",
      },
      {
        name: "Papua New Guinea",
        dial_code: "+675",
        code: "PG",
      },
      {
        name: "Paraguay",
        dial_code: "+595",
        code: "PY",
      },
      {
        name: "Peru",
        dial_code: "+51",
        code: "PE",
      },
      {
        name: "Philippines",
        dial_code: "+63",
        code: "PH",
      },
      {
        name: "Pitcairn",
        dial_code: "+872",
        code: "PN",
      },
      {
        name: "Poland",
        dial_code: "+48",
        code: "PL",
      },
      {
        name: "Portugal",
        dial_code: "+351",
        code: "PT",
      },
      {
        name: "Puerto Rico",
        dial_code: "+1939",
        code: "PR",
      },
      {
        name: "Qatar",
        dial_code: "+974",
        code: "QA",
      },
      {
        name: "Romania",
        dial_code: "+40",
        code: "RO",
      },
      {
        name: "Russia",
        dial_code: "+7",
        code: "RU",
      },
      {
        name: "Rwanda",
        dial_code: "+250",
        code: "RW",
      },
      {
        name: "Reunion",
        dial_code: "+262",
        code: "RE",
      },
      {
        name: "Saint Barthelemy",
        dial_code: "+590",
        code: "BL",
      },
      {
        name: "Saint Helena, Ascension and Tristan Da Cunha",
        dial_code: "+290",
        code: "SH",
      },
      {
        name: "Saint Kitts and Nevis",
        dial_code: "+1869",
        code: "KN",
      },
      {
        name: "Saint Lucia",
        dial_code: "+1758",
        code: "LC",
      },
      {
        name: "Saint Martin",
        dial_code: "+590",
        code: "MF",
      },
      {
        name: "Saint Pierre and Miquelon",
        dial_code: "+508",
        code: "PM",
      },
      {
        name: "Saint Vincent and the Grenadines",
        dial_code: "+1784",
        code: "VC",
      },
      {
        name: "Samoa",
        dial_code: "+685",
        code: "WS",
      },
      {
        name: "San Marino",
        dial_code: "+378",
        code: "SM",
      },
      {
        name: "Sao Tome and Principe",
        dial_code: "+239",
        code: "ST",
      },
      {
        name: "Saudi Arabia",
        dial_code: "+966",
        code: "SA",
      },
      {
        name: "Senegal",
        dial_code: "+221",
        code: "SN",
      },
      {
        name: "Serbia",
        dial_code: "+381",
        code: "RS",
      },
      {
        name: "Seychelles",
        dial_code: "+248",
        code: "SC",
      },
      {
        name: "Sierra Leone",
        dial_code: "+232",
        code: "SL",
      },
      {
        name: "Singapore",
        dial_code: "+65",
        code: "SG",
      },
      {
        name: "Slovakia",
        dial_code: "+421",
        code: "SK",
      },
      {
        name: "Slovenia",
        dial_code: "+386",
        code: "SI",
      },
      {
        name: "Solomon Islands",
        dial_code: "+677",
        code: "SB",
      },
      {
        name: "Somalia",
        dial_code: "+252",
        code: "SO",
      },
      {
        name: "South Africa",
        dial_code: "+27",
        code: "ZA",
      },
      {
        name: "South Sudan",
        dial_code: "+211",
        code: "SS",
      },
      {
        name: "South Georgia and the South Sandwich Islands",
        dial_code: "+500",
        code: "GS",
      },
      {
        name: "Spain",
        dial_code: "+34",
        code: "ES",
      },
      {
        name: "Sri Lanka",
        dial_code: "+94",
        code: "LK",
      },
      {
        name: "Sudan",
        dial_code: "+249",
        code: "SD",
      },
      {
        name: "Suriname",
        dial_code: "+597",
        code: "SR",
      },
      {
        name: "Svalbard and Jan Mayen",
        dial_code: "+47",
        code: "SJ",
      },
      {
        name: "Swaziland",
        dial_code: "+268",
        code: "SZ",
      },
      {
        name: "Sweden",
        dial_code: "+46",
        code: "SE",
      },
      {
        name: "Switzerland",
        dial_code: "+41",
        code: "CH",
      },
      {
        name: "Syrian Arab Republic",
        dial_code: "+963",
        code: "SY",
      },
      {
        name: "Taiwan",
        dial_code: "+886",
        code: "TW",
      },
      {
        name: "Tajikistan",
        dial_code: "+992",
        code: "TJ",
      },
      {
        name: "Tanzania, United Republic of Tanzania",
        dial_code: "+255",
        code: "TZ",
      },
      {
        name: "Thailand",
        dial_code: "+66",
        code: "TH",
      },
      {
        name: "Timor-Leste",
        dial_code: "+670",
        code: "TL",
      },
      {
        name: "Togo",
        dial_code: "+228",
        code: "TG",
      },
      {
        name: "Tokelau",
        dial_code: "+690",
        code: "TK",
      },
      {
        name: "Tonga",
        dial_code: "+676",
        code: "TO",
      },
      {
        name: "Trinidad and Tobago",
        dial_code: "+1868",
        code: "TT",
      },
      {
        name: "Tunisia",
        dial_code: "+216",
        code: "TN",
      },
      {
        name: "Turkey",
        dial_code: "+90",
        code: "TR",
      },
      {
        name: "Turkmenistan",
        dial_code: "+993",
        code: "TM",
      },
      {
        name: "Turks and Caicos Islands",
        dial_code: "+1649",
        code: "TC",
      },
      {
        name: "Tuvalu",
        dial_code: "+688",
        code: "TV",
      },
      {
        name: "Uganda",
        dial_code: "+256",
        code: "UG",
      },
      {
        name: "Ukraine",
        dial_code: "+380",
        code: "UA",
      },
      {
        name: "United Arab Emirates",
        dial_code: "+971",
        code: "AE",
      },
      {
        name: "United Kingdom",
        dial_code: "+44",
        code: "GB",
      },
      {
        name: "United States",
        dial_code: "+1",
        code: "US",
      },
      {
        name: "Uruguay",
        dial_code: "+598",
        code: "UY",
      },
      {
        name: "Uzbekistan",
        dial_code: "+998",
        code: "UZ",
      },
      {
        name: "Vanuatu",
        dial_code: "+678",
        code: "VU",
      },
      {
        name: "Venezuela, Bolivarian Republic of Venezuela",
        dial_code: "+58",
        code: "VE",
      },
      {
        name: "Vietnam",
        dial_code: "+84",
        code: "VN",
      },
      {
        name: "Virgin Islands, British",
        dial_code: "+1284",
        code: "VG",
      },
      {
        name: "Virgin Islands, U.S.",
        dial_code: "+1340",
        code: "VI",
      },
      {
        name: "Wallis and Futuna",
        dial_code: "+681",
        code: "WF",
      },
      {
        name: "Yemen",
        dial_code: "+967",
        code: "YE",
      },
      {
        name: "Zambia",
        dial_code: "+260",
        code: "ZM",
      },
      {
        name: "Zimbabwe",
        dial_code: "+263",
        code: "ZW",
      },
    ];

    let country = {
      Andorra: "Andorra",
      Dubai: "United Arab Emirates",
      Kabul: "Afghanistan",
      Tirane: "Albania",
      Yerevan: "Armenia",
      Casey: "Antarctica",
      Davis: "Antarctica",
      Mawson: "Antarctica",
      Palmer: "Antarctica",
      Rothera: "Antarctica",
      Troll: "Antarctica",
      Vostok: "Antarctica",
      Buenos_Aires: "Argentina",
      Cordoba: "Argentina",
      Salta: "Argentina",
      Jujuy: "Argentina",
      Tucuman: "Argentina",
      Catamarca: "Argentina",
      La_Rioja: "Argentina",
      San_Juan: "Argentina",
      Mendoza: "Argentina",
      San_Luis: "Argentina",
      Rio_Gallegos: "Argentina",
      Ushuaia: "Argentina",
      Pago_Pago: "Samoa (American)",
      Vienna: "Austria",
      Lord_Howe: "Australia",
      Macquarie: "Australia",
      Hobart: "Australia",
      Melbourne: "Australia",
      Sydney: "Australia",
      Broken_Hill: "Australia",
      Brisbane: "Australia",
      Lindeman: "Australia",
      Adelaide: "Australia",
      Darwin: "Australia",
      Perth: "Australia",
      Eucla: "Australia",
      Baku: "Azerbaijan",
      Barbados: "Barbados",
      Dhaka: "Bangladesh",
      Brussels: "Belgium",
      Sofia: "Bulgaria",
      Bermuda: "Bermuda",
      Brunei: "Brunei",
      La_Paz: "Bolivia",
      Noronha: "Brazil",
      Belem: "Brazil",
      Fortaleza: "Brazil",
      Recife: "Brazil",
      Araguaina: "Brazil",
      Maceio: "Brazil",
      Bahia: "Brazil",
      Sao_Paulo: "Brazil",
      Campo_Grande: "Brazil",
      Cuiaba: "Brazil",
      Santarem: "Brazil",
      Porto_Velho: "Brazil",
      Boa_Vista: "Brazil",
      Manaus: "Brazil",
      Eirunepe: "Brazil",
      Rio_Branco: "Brazil",
      Thimphu: "Bhutan",
      Minsk: "Belarus",
      Belize: "Belize",
      St_Johns: "Canada",
      Halifax: "Canada",
      Glace_Bay: "Canada",
      Moncton: "Canada",
      Goose_Bay: "Canada",
      Toronto: "Canada",
      Nipigon: "Canada",
      Thunder_Bay: "Canada",
      Iqaluit: "Canada",
      Pangnirtung: "Canada",
      Winnipeg: "Canada",
      Rainy_River: "Canada",
      Resolute: "Canada",
      Rankin_Inlet: "Canada",
      Regina: "Canada",
      Swift_Current: "Canada",
      Edmonton: "Canada",
      Cambridge_Bay: "Canada",
      Yellowknife: "Canada",
      Inuvik: "Canada",
      Dawson_Creek: "Canada",
      Fort_Nelson: "Canada",
      Whitehorse: "Canada",
      Dawson: "Canada",
      Vancouver: "Canada",
      Cocos: "Cocos (Keeling) Islands",
      Zurich: "Switzerland",
      Abidjan: "Côte d'Ivoire",
      Rarotonga: "Cook Islands",
      Santiago: "Chile",
      Punta_Arenas: "Chile",
      Easter: "Chile",
      Shanghai: "China",
      Urumqi: "China",
      Bogota: "Colombia",
      Costa_Rica: "Costa Rica",
      Havana: "Cuba",
      Cape_Verde: "Cape Verde",
      Christmas: "Christmas Island",
      Nicosia: "Cyprus",
      Famagusta: "Cyprus",
      Prague: "Czech Republic",
      Berlin: "Germany",
      Copenhagen: "Denmark",
      Santo_Domingo: "Dominican Republic",
      Algiers: "Algeria",
      Guayaquil: "Ecuador",
      Galapagos: "Ecuador",
      Tallinn: "Estonia",
      Cairo: "Egypt",
      El_Aaiun: "Western Sahara",
      Madrid: "Spain",
      Ceuta: "Spain",
      Canary: "Spain",
      Helsinki: "Finland",
      Fiji: "Fiji",
      Stanley: "Falkland Islands",
      Chuuk: "Micronesia",
      Pohnpei: "Micronesia",
      Kosrae: "Micronesia",
      Faroe: "Faroe Islands",
      Paris: "France",
      London: "Britain (UK)",
      Tbilisi: "Georgia",
      Cayenne: "French Guiana",
      Gibraltar: "Gibraltar",
      Nuuk: "Greenland",
      Danmarkshavn: "Greenland",
      Scoresbysund: "Greenland",
      Thule: "Greenland",
      Athens: "Greece",
      South_Georgia: "South Georgia & the South Sandwich Islands",
      Guatemala: "Guatemala",
      Guam: "Guam",
      Bissau: "Guinea-Bissau",
      Guyana: "Guyana",
      Hong_Kong: "Hong Kong",
      Tegucigalpa: "Honduras",
      "Port-au-Prince": "Haiti",
      Budapest: "Hungary",
      Jakarta: "Indonesia",
      Pontianak: "Indonesia",
      Makassar: "Indonesia",
      Jayapura: "Indonesia",
      Dublin: "Ireland",
      Jerusalem: "Israel",
      Kolkata: "India",
      Chagos: "British Indian Ocean Territory",
      Baghdad: "Iraq",
      Tehran: "Iran",
      Reykjavik: "Iceland",
      Rome: "Italy",
      Jamaica: "Jamaica",
      Amman: "Jordan",
      Tokyo: "Japan",
      Nairobi: "Kenya",
      Bishkek: "Kyrgyzstan",
      Tarawa: "Kiribati",
      Kanton: "Kiribati",
      Kiritimati: "Kiribati",
      Pyongyang: "Korea (North)",
      Seoul: "Korea (South)",
      Almaty: "Kazakhstan",
      Qyzylorda: "Kazakhstan",
      Qostanay: "Kazakhstan",
      Aqtobe: "Kazakhstan",
      Aqtau: "Kazakhstan",
      Atyrau: "Kazakhstan",
      Oral: "Kazakhstan",
      Beirut: "Lebanon",
      Colombo: "Sri Lanka",
      Monrovia: "Liberia",
      Vilnius: "Lithuania",
      Luxembourg: "Luxembourg",
      Riga: "Latvia",
      Tripoli: "Libya",
      Casablanca: "Morocco",
      Monaco: "Monaco",
      Chisinau: "Moldova",
      Majuro: "Marshall Islands",
      Kwajalein: "Marshall Islands",
      Yangon: "Myanmar (Burma)",
      Ulaanbaatar: "Mongolia",
      Hovd: "Mongolia",
      Choibalsan: "Mongolia",
      Macau: "Macau",
      Martinique: "Martinique",
      Malta: "Malta",
      Mauritius: "Mauritius",
      Maldives: "Maldives",
      Mexico_City: "Mexico",
      Cancun: "Mexico",
      Merida: "Mexico",
      Monterrey: "Mexico",
      Matamoros: "Mexico",
      Mazatlan: "Mexico",
      Chihuahua: "Mexico",
      Ojinaga: "Mexico",
      Hermosillo: "Mexico",
      Tijuana: "Mexico",
      Bahia_Banderas: "Mexico",
      Kuala_Lumpur: "Malaysia",
      Kuching: "Malaysia",
      Maputo: "Mozambique",
      Windhoek: "Namibia",
      Noumea: "New Caledonia",
      Norfolk: "Norfolk Island",
      Lagos: "Nigeria",
      Managua: "Nicaragua",
      Amsterdam: "Netherlands",
      Oslo: "Norway",
      Kathmandu: "Nepal",
      Nauru: "Nauru",
      Niue: "Niue",
      Auckland: "New Zealand",
      Chatham: "New Zealand",
      Panama: "Panama",
      Lima: "Peru",
      Tahiti: "French Polynesia",
      Marquesas: "French Polynesia",
      Gambier: "French Polynesia",
      Port_Moresby: "Papua New Guinea",
      Bougainville: "Papua New Guinea",
      Manila: "Philippines",
      Karachi: "Pakistan",
      Warsaw: "Poland",
      Miquelon: "St Pierre & Miquelon",
      Pitcairn: "Pitcairn",
      Puerto_Rico: "Puerto Rico",
      Gaza: "Palestine",
      Hebron: "Palestine",
      Lisbon: "Portugal",
      Madeira: "Portugal",
      Azores: "Portugal",
      Palau: "Palau",
      Asuncion: "Paraguay",
      Qatar: "Qatar",
      Reunion: "Réunion",
      Bucharest: "Romania",
      Belgrade: "Serbia",
      Kaliningrad: "Russia",
      Moscow: "Russia",
      Simferopol: "Russia",
      Kirov: "Russia",
      Volgograd: "Russia",
      Astrakhan: "Russia",
      Saratov: "Russia",
      Ulyanovsk: "Russia",
      Samara: "Russia",
      Yekaterinburg: "Russia",
      Omsk: "Russia",
      Novosibirsk: "Russia",
      Barnaul: "Russia",
      Tomsk: "Russia",
      Novokuznetsk: "Russia",
      Krasnoyarsk: "Russia",
      Irkutsk: "Russia",
      Chita: "Russia",
      Yakutsk: "Russia",
      Khandyga: "Russia",
      Vladivostok: "Russia",
      "Ust-Nera": "Russia",
      Magadan: "Russia",
      Sakhalin: "Russia",
      Srednekolymsk: "Russia",
      Kamchatka: "Russia",
      Anadyr: "Russia",
      Riyadh: "Saudi Arabia",
      Guadalcanal: "Solomon Islands",
      Mahe: "Seychelles",
      Khartoum: "Sudan",
      Stockholm: "Sweden",
      Singapore: "Singapore",
      Paramaribo: "Suriname",
      Juba: "South Sudan",
      Sao_Tome: "Sao Tome & Principe",
      El_Salvador: "El Salvador",
      Damascus: "Syria",
      Grand_Turk: "Turks & Caicos Is",
      Ndjamena: "Chad",
      Kerguelen: "French Southern & Antarctic Lands",
      Bangkok: "Thailand",
      Dushanbe: "Tajikistan",
      Fakaofo: "Tokelau",
      Dili: "East Timor",
      Ashgabat: "Turkmenistan",
      Tunis: "Tunisia",
      Tongatapu: "Tonga",
      Istanbul: "Turkey",
      Funafuti: "Tuvalu",
      Taipei: "Taiwan",
      Kiev: "Ukraine",
      Uzhgorod: "Ukraine",
      Zaporozhye: "Ukraine",
      Wake: "US minor outlying islands",
      New_York: "United States",
      Detroit: "United States",
      Louisville: "United States",
      Monticello: "United States",
      Indianapolis: "United States",
      Vincennes: "United States",
      Winamac: "United States",
      Marengo: "United States",
      Petersburg: "United States",
      Vevay: "United States",
      Chicago: "United States",
      Tell_City: "United States",
      Knox: "United States",
      Menominee: "United States",
      Center: "United States",
      New_Salem: "United States",
      Beulah: "United States",
      Denver: "United States",
      Boise: "United States",
      Phoenix: "United States",
      Los_Angeles: "United States",
      Anchorage: "United States",
      Juneau: "United States",
      Sitka: "United States",
      Metlakatla: "United States",
      Yakutat: "United States",
      Nome: "United States",
      Adak: "United States",
      Honolulu: "United States",
      Montevideo: "Uruguay",
      Samarkand: "Uzbekistan",
      Tashkent: "Uzbekistan",
      Caracas: "Venezuela",
      Ho_Chi_Minh: "Vietnam",
      Efate: "Vanuatu",
      Wallis: "Wallis & Futuna",
      Apia: "Samoa (western)",
      Johannesburg: "South Africa",
      Antigua: "Antigua & Barbuda",
      Anguilla: "Anguilla",
      Luanda: "Angola",
      McMurdo: "Antarctica",
      DumontDUrville: "Antarctica",
      Syowa: "Antarctica",
      Aruba: "Aruba",
      Mariehamn: "Åland Islands",
      Sarajevo: "Bosnia & Herzegovina",
      Ouagadougou: "Burkina Faso",
      Bahrain: "Bahrain",
      Bujumbura: "Burundi",
      "Porto-Novo": "Benin",
      St_Barthelemy: "St Barthelemy",
      Kralendijk: "Caribbean NL",
      Nassau: "Bahamas",
      Gaborone: "Botswana",
      "Blanc-Sablon": "Canada",
      Atikokan: "Canada",
      Creston: "Canada",
      Kinshasa: "Congo (Dem. Rep.)",
      Lubumbashi: "Congo (Dem. Rep.)",
      Bangui: "Central African Rep.",
      Brazzaville: "Congo (Rep.)",
      Douala: "Cameroon",
      Curacao: "Curaçao",
      Busingen: "Germany",
      Djibouti: "Djibouti",
      Dominica: "Dominica",
      Asmara: "Eritrea",
      Addis_Ababa: "Ethiopia",
      Libreville: "Gabon",
      Grenada: "Grenada",
      Guernsey: "Guernsey",
      Accra: "Ghana",
      Banjul: "Gambia",
      Conakry: "Guinea",
      Guadeloupe: "Guadeloupe",
      Malabo: "Equatorial Guinea",
      Zagreb: "Croatia",
      Isle_of_Man: "Isle of Man",
      Jersey: "Jersey",
      Phnom_Penh: "Cambodia",
      Comoro: "Comoros",
      St_Kitts: "St Kitts & Nevis",
      Kuwait: "Kuwait",
      Cayman: "Cayman Islands",
      Vientiane: "Laos",
      St_Lucia: "St Lucia",
      Vaduz: "Liechtenstein",
      Maseru: "Lesotho",
      Podgorica: "Montenegro",
      Marigot: "St Martin (French)",
      Antananarivo: "Madagascar",
      Skopje: "North Macedonia",
      Bamako: "Mali",
      Saipan: "Northern Mariana Islands",
      Nouakchott: "Mauritania",
      Montserrat: "Montserrat",
      Blantyre: "Malawi",
      Niamey: "Niger",
      Muscat: "Oman",
      Kigali: "Rwanda",
      St_Helena: "St Helena",
      Ljubljana: "Slovenia",
      Longyearbyen: "Svalbard & Jan Mayen",
      Bratislava: "Slovakia",
      Freetown: "Sierra Leone",
      San_Marino: "San Marino",
      Dakar: "Senegal",
      Mogadishu: "Somalia",
      Lower_Princes: "St Maarten (Dutch)",
      Mbabane: "Eswatini (Swaziland)",
      Lome: "Togo",
      Port_of_Spain: "Trinidad & Tobago",
      Dar_es_Salaam: "Tanzania",
      Kampala: "Uganda",
      Midway: "US minor outlying islands",
      Vatican: "Vatican City",
      St_Vincent: "St Vincent",
      Tortola: "Virgin Islands (UK)",
      St_Thomas: "Virgin Islands (US)",
      Aden: "Yemen",
      Mayotte: "Mayotte",
      Lusaka: "Zambia",
      Harare: "Zimbabwe",
    };

    // this.IsVerifySuccessfull=true;

    this.profileImageUrl = environment.defaultRadiologistImage;

    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (timezone != null) {
      let cityName = timezone.split("/")[1];
      // alert(cityName);

      if (country[cityName]) {
        this.selectedPhoneCode = this.CountryWithCode.find(
          (x) => x.name == country[cityName]
        );

        if (this.selectedPhoneCode) {
          // this.selectedPhoneNo=this.selectedPhoneCode.dial_code;
        }
      }
    }

    this.newUser = new User();
    this.newUser.hrEmpdocuments = [];
    this.IsCountDownTimeOut = false;
    this.items = [
      {
        label: "Radiologist Sign up",
        // routerLink: 'personal'
      },
      {
        label: "Phone Number Verification",
        // routerLink: 'seat'
      },
      {
        label: "Email Address Verification",
        // routerLink: 'payment'
      },
      {
        label: "Confirmation",
        // routerLink: 'confirmation'
      },
    ];

    this.selectedColumns = [
      { field: "Type", header: "Type" },

      { field: "Format", header: "Format" },
      { field: "Title", header: "Title" },
    ];

    this.lineProfileImageUrl =
      "https://i.insider.com/5fbd515550e71a001155724f?width=700";

    this.disable = false;
    this.saveOrUpdateButtonLabel = "Save";

    this.radiogistForm = new FormGroup(
      {
        FName: new FormControl("", Validators.required),
        LName: new FormControl("", Validators.required),
        Email: new FormControl("", emailValidator),
        Password: new FormControl("", Validators.required),
        ConfirmPassword: new FormControl("", Validators.required),
        LineId: new FormControl(),
        PhoneNo: new FormControl(
          "",
          Validators.compose([Validators.pattern("[0-9]+")])
        ),
        phoneCode: new FormControl(),
        Country: new FormControl("", Validators.required),
        District: new FormControl(),
        Province: new FormControl(),
        SubDistrict: new FormControl(),
        SubSpecialty: new FormControl(),
        HospitalIds: new FormControl(Validators.required),
        ZipCode: new FormControl(),
        MedicalLincence: new FormControl(),
        RefDocName: new FormControl(),
        RefDocTelNo: new FormControl(),
        TearmAndCondition: new FormControl(false, Validators.required),
      },
      { validators: this.passwordMatch }
    );

    this._activateRoute.params.subscribe((params) => {
      this.LineId = params.LineId;
    });

    if (this.LineId) {
      this.profileImageUrl = environment.profileImagerUrl + "/Profile/";

      this.disable = true;
      this.saveOrUpdateButtonLabel = "Update";
      this._userService.getUserByLineId(this.LineId).subscribe((user) => {
        if (user) {
          this.selectedHrUser = user;
          if (user.ImgFileName) {
            this.profileImageUrl =
              environment.profileImagerUrl +
              "/Profile/" +
              user.OrgId +
              "/" +
              user.EmpId +
              "/" +
              user.ImgFileName;
          }

          this.selectedEmail = user.EmailOfficial;
          this.selectedLineId = user.LineId;
        }
      });
    }

    this.loadStartUpData();
    this.loadSubspecialist();
    this.uploadUrl = environment.apiUrl + "User/fileupload";

    this.documentTypedDopdownItems = [
      { name: "NID", code: "NID" },
      { name: "Passport", code: "Passport" },
      { name: "Medical License", code: "Medical License" },
      { name: "Others", code: "Others" },
    ];
  }

  private getFilterHospitalList(type: string) {
    this._siteService
      .getFilterHospitalList(type)
      .subscribe((res: GeneralResModel<SiteInformation[]>) => {
        if (res.IsSucceed) {
          this.siteList = res.Result;
        }
      });
  }

  // getCustometHospitalList() {
  //   this._siteService
  //     .getCustometHospitalList()
  //     .subscribe((res: SiteInformation[]) => {
  //       if (res.length > 0) {
  //         this.siteList = res;
  //       }
  //     });
  // }

  radiographerShow() {
    if (this.isRadiographer) {
      this.registrationHeader = "Sign up as A Radiographer";
    } else {
      this.registrationHeader = "Sign up as A Radiologist";
    }
  }

  loadSubspecialist() {
    this._userService.getSubSpecialtyList().subscribe((x) => {
      if (x) {
        this.SubSpecialtyList = x;
      }
    });
  }

  loadStartUpData() {
    this._userService.getCountryList().subscribe((x) => {
      if (x) {
        this.countryList = x;
      }
    });
  }

  loadProvince() {
    this._userService
      .getProvinceList(this.selectedCountry.CountryId)
      .subscribe((x) => {
        if (x) {
          this.ProvinceList = x;
        }
      });

    // this.EmmitRediologist();
  }

  loadDistrict() {
    this._userService
      .getDistrictList(this.selectedProvince.ProvinceId)
      .subscribe((x) => {
        if (x) {
          this.districtList = x;
        }
      });
    // this.EmmitRediologist();
  }

  loadSubDistrict() {
    this._userService
      .getSubDistrictList(this.selectedDistrict.DistrictId)
      .subscribe((x) => {
        if (x) {
          this.SubDistrictList = x;
        }
      })
      .add(() => {
        if (this.districtList.length > 0) {
          this.loadPostalCodesByDistricId(this.selectedDistrict.DistrictId);
        }
      });
  }

  loadPostalCodesByDistricId(districtId: number) {
    this._userService
      .getPostalCodesByDistricId(districtId)
      .subscribe((res: GeneralResModel<GblPostalCode[]>) => {
        if (res.IsSucceed) {
          this.postalCodeList = res.Result;
        }
      });
  }

  passwordMatch(g: FormGroup) {
    return g.get("Password").value === g.get("ConfirmPassword").value
      ? null
      : { mismatch: true };
  }

  saveOrUpdateRediologist() {
    let userForm = new FormData();

    userForm.append("Fname", this.selectedFName);
    userForm.append("Lname", this.selectedLName);
    userForm.append("EmailOfficial", this.selectedEmail);
    userForm.append("Pwd", this.selectedPassword);
    userForm.append("PhoneMobile", this.selectedPhoneNo);

    if (this.selectedCountry) {
      userForm.append("CountryId", this.selectedCountry.CountryId + "");
    }
    if (this.selectedDistrict) {
      userForm.append("DistrictId", this.selectedDistrict.DistrictId + "");
    }

    if (this.selectedProvince) {
      userForm.append("ProvinceId", this.selectedProvince.ProvinceId + "");
    }

    if (this.selectedSubDistrict) {
      userForm.append(
        "SubdistrictId",
        this.selectedSubDistrict.SubdistrictId + ""
      );
    }

    if (this.selectedZipCode) {
      userForm.append("ZipCode", this.selectedZipCode.PostalCode.toString());

      userForm.append(
        "PostalCodeId",
        this.selectedZipCode.PostalCodeId.toString()
      );
    }

    if (this.selectedSubSpecialty?.length) {
      for (let i = 0; i < this.selectedSubSpecialty?.length; i++) {
        userForm.append(
          "HrEmpsubspecialtyId",
          this.selectedSubSpecialty[i].SubspecialtyId.toString()
        );
      }
    }

    if (this.selectedSite?.length) {
      for (let i = 0; i < this.selectedSite?.length; i++) {
        userForm.append("HospitalIds", this.selectedSite[i].OrgId.toString());
      }
    }

    if (this.selectedPhoneCode) {
      userForm.append("CountryDialCode", this.selectedPhoneCode.dial_code);
    }

    if (this.selectedLineId) {
      userForm.append("LineId", this.selectedLineId);
    }

    if (this.LineId) {
      userForm.append("OrgId", this.selectedHrUser.OrgId.toString());
      // this.newUser.OrgId=this.selectedHrUser.OrgId;
    }

    if (this.ProfileImageToUpload) {
      userForm.append("profilePictureFile", this.ProfileImageToUpload);
    }

    if (this.selectedMedicalLincence) {
      userForm.append("MedicalLincence", this.selectedMedicalLincence);
    }

    if (this.newUser.hrEmpdocuments.length) {
      for (let i = 0; i < this.newUser.hrEmpdocuments.length; i++) {
        userForm.append("hrEmpdocuments", this.newUser.hrEmpdocuments[i].File);

        userForm.append(
          "hrEmpdocumentFileTitle",
          this.newUser.hrEmpdocuments[i].Title
        );
        userForm.append(
          "hrEmpdocumentFileFormat",
          this.newUser.hrEmpdocuments[i].Format
        );
        userForm.append(
          "hrEmpdocumentFileType",
          this.newUser.hrEmpdocuments[i].Type
        );
      }
    }

    if (this.selectedRefDocName) {
      userForm.append("RefDocName", this.selectedRefDocName);
    }

    if (this.selectedRefDocTelNo) {
      userForm.append("RefDocTelNo", this.selectedRefDocTelNo);
    }
    userForm.append("IsRadiographer", this.isRadiographer.toString());
    this.newUser.Fname = this.selectedFName;
    this.newUser.Lname = this.selectedLName;
    this.newUser.EmailOfficial = this.selectedEmail;
    this.newUser.Pwd = this.selectedPassword;
    this.newUser.PhoneMobile = this.selectedPhoneNo;
    // this.newUser.ZipCode = this.selectedZipCode.PostalCode;
    // this.newUser.PostalCodeId = this.selectedZipCode.PostalCodeId;
    // this.newUser.CountryId = this.selectedCountry?.CountryId;
    // this.newUser.DistrictId = this.selectedDistrict?.DistrictId;
    // this.newUser.ProvinceId = this.selectedProvince?.ProvinceId;
    // this.newUser.SubdistrictId = this.selectedSubDistrict?.SubdistrictId;
    this.newUser.HrEmpsubspecialty = this.selectedSubSpecialty;
    this.newUser.HospitalIds = this.selectedSite;
    this.newUser.IsRadiographer = this.isRadiographer;

    this.newUser.LineId = this.selectedLineId;
    if (this.LineId) {
      this.newUser.OrgId = this.selectedHrUser.OrgId;
    }

    this.isLoaderVisible = true;
    // window.open("https://radportal.net/thank-you", "_self");

    this._userService.addNewRadiologist(userForm).subscribe(
      (x) => {
        if (x) {
          // this.uploadProfilePicture(x);
          this.alertifyService.success("Radiologist created successfully");

          window.open("https://vetportal.vet/thank-you/", "_self");
          //window.open("https://app.vetportal.vet/#/login", "_self");
        } else {
          this.isLoaderVisible = false;
        }
      },
      (error) => {
        this.isLoaderVisible = false;
      }
    );

    // this.userEmitter.emit(user);
  }

  onEmailOtpChange(code) {
    this.selectedEmailOTP = code;
  }

  onOtpPhoneChange(code) {
    this.selectedPhoneOtp = code;
  }
  sentVerificationCode() {
    let userVerify = new HrEmpVerify();
    this.IsCountDownTimeOut = false;

    userVerify.Email = this.selectedEmail;
    userVerify.PhoneNo = this.selectedPhoneNo;
    userVerify.IsEmailVerified = this.IsEmailVerified;
    userVerify.IsPhoneVerified = this.IsPhoneVerified;

    userVerify.CountryDialCode = this.selectedPhoneCode.dial_code;

    this.isLoaderVisible = true;

    //need to comment
    // this.start(1);
    this.stop();
    this.userVerifyService.SendVerificationCode(userVerify).subscribe(
      (x) => {
        if (x) {
          this.IsSentVerificationCode = true;
          this.VerificationId = x.VerifyId;
          this.showOrHideResendButton = false;
          this.start(10);

          this.isLoaderVisible = false;
        } else {
          this.isLoaderVisible = false;
          this.alertifyService.error("Failed to send Verification code");
        }
      },
      (error) => {
        this.isLoaderVisible = false;
        this.alertifyService.error("Failed to send Verification code");
      }
    );

    //need to comment
  }

  reset() {
    this.IsSentVerificationCode = false;
    this.stop();
  }
  VerifyOtp() {
    //need to comment
    // this.VerificationId=8;
    //  this.IsVerifySuccessfull=true;

    //need to comment
    let userVerify = new HrEmpVerify();
    userVerify.VerifyId = this.VerificationId;
    userVerify.EmailOtp = this.selectedEmailOTP;
    userVerify.PhoneOtp = this.selectedPhoneOtp;

    this.userVerifyService.verifyOTP(userVerify).subscribe((x) => {
      if (x) {
        if (!this.IsEmailVerified) {
          this.IsEmailVerified = x.IsEmailVerified;
        }
        if (!this.IsPhoneVerified) {
          this.IsPhoneVerified = x.IsPhoneVerified;
        }

        if (this.IsPhoneVerified && this.IsEmailVerified) {
          this.IsVerifySuccessfull = true;
        } else {
          this.showOrHideResendButton = true;

          if (this.IsPhoneVerified) {
            this.ngOtpInputRefPhone.otpForm.disable();
          } else {
            this.ngOtpInputRefPhone.setValue("");
          }

          if (this.IsEmailVerified) {
            this.ngOtpInputRefEmail.otpForm.disable();
          } else {
            this.ngOtpInputRefEmail.setValue("");
          }
        }

        this.stop();
      }
    });
  }

  myUploader(event): void {
    if (event.files.length === 0) {
      return;
    }
    this.ProfileImageToUpload = event.files[0];
  }

  uploadProfilePicture(user: User) {
    let input = new FormData();
    input.append("file", this.ProfileImageToUpload);
    input.append("userId", user.EmpId.toString());
    input.append("orgId", user.OrgId.toString());

    this.httpClient.post(this.uploadUrl, input, {}).subscribe((res) => {
      // this.alertifyService.success("Photo updated successfully!");
    });
  }

  onUpload(event): void {
    for (const file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  OpenDocumentPopUp() {
    this.displayDocumentUploadDialog = true;
  }

  closeDocumentDialog() {}

  documentUploader(event): void {
    this.userDocumet = new HrEmpdocument();
    this.documentFile = event.target.files[0];
    if (this.documentFile) {
      let fileName = this.documentFile.name;
      this.userDocumet.Title = fileName.split(".").slice(0, -1).join(".");

      this.userDocumet.Format = fileName.split(".").pop();
      this.userDocumet.File = this.documentFile;

      this.userDocumet.GUID = this.makeid(7);

      //alert(this.newUser.hrEmpdocuments[0].Title);
    } else {
      this.alertifyService.warning("Please select a file first");
    }
  }

  uploadDocuments() {
    if (this.userDocumet?.File) {
      this.userDocumet.Type = this.selectedDocumentType.code;

      this.newUser.hrEmpdocuments.push(this.userDocumet);

      this.userDocumet = new HrEmpdocument();
      this.selecetdInput = null;
    }
  }

  previewOrderDocument(orderDocumentData: HrEmpdocument) {}

  deleteOrderDocument(document: HrEmpdocument) {
    console.log(document);
    this.newUser.hrEmpdocuments = this.newUser.hrEmpdocuments.filter(
      (x) => x.GUID != document.GUID
    );
  }

  makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  start(time) {
    this.timer(time);
  }
  stop() {
    clearInterval(this.timerInterval);
  }

  timer(minute) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        this.IsCountDownTimeOut = true;
        this.showOrHideResendButton = true;
        console.log("finished");
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  checkEmail(event) {
    this.emailCheck();
    this.searchEmailTextSubject$.next({
      email: this.radiogistForm.controls["Email"].value,
      Id: 0,
    });
  }

  emailCheck() {
    this.searchEmailTextSubject$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((searchText) =>
          this._userService.emailAlreayExists(searchText.email)
        )
      )
      .subscribe((status) => {
        this.emailExists = status;
        // console.log('email exists ----',this.emailExists);
      });
  }

  checkPhone(event) {
    this.phoneCheck();
    this.searchPhoneTextSubject$.next({
      phone: this.radiogistForm.controls["PhoneNo"].value,
      Id: 0,
      countryDialCode: this.selectedPhoneCode.dial_code,
    });
  }

  phoneCheck() {
    this.searchPhoneTextSubject$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((searchText) =>
          this._userService.phoneAlreayExists(
            searchText.phone,
            searchText.countryDialCode
          )
        )
      )
      .subscribe((status) => {
        this.PhoneExists = status;
        // console.log('email exists ----',this.emailExists);
      });
  }

  startOverPage() {
    window.location.reload();
  }

  isEmailValid() {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.selectedEmail).toLowerCase());
  }
}
