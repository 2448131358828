import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ExamDtoModel,
  ExamOrderRequestModel,
  GeneralResModel,
  PatientInfoViewModel,
  RisExamDto,
  RisExamPanelDtlModel,
  RisOrderModel,
} from "src/app/_models";
import { CommonResponse } from "src/app/_models/common-response.modle";
import { ExamConsumable } from "src/app/_models/exam/exam-consumable.model";
import { ExamPanel } from "src/app/_models/exam/exam-panel.model";
import { ExamReferencevalue } from "src/app/_models/exam/exam-referencevalue.model";
import { ExamResult } from "src/app/_models/exam/exam-result.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ExamInfoService {
  private baseURL = `${environment.apiUrl}examInfo/`;

  constructor(private _httpClient: HttpClient) {}

  // ExamConsumable API
  getExamConsumableByExamId(examId: number) {
    return this._httpClient.get<CommonResponse>(
      `${this.baseURL}GetExamConsumableByExamId/${examId}`
    );
  }
  removeAllChildExamConsumable(body: ExamConsumable) {
    return this._httpClient.post<CommonResponse>(
      `${this.baseURL}RemoveAllChildExamConsumable`,
      body
    );
  }
  getExamConsumableByOrgId(orgId: number) {
    return this._httpClient.get<CommonResponse>(
      `${this.baseURL}GetExamConsumableByOrgId/${orgId}`
    );
  }
  addExamconsumables(request: ExamConsumable) {
    return this._httpClient.post<CommonResponse>(
      `${this.baseURL}AddExamconsumables`,
      request
    );
  }

  updateExamconsumables(request: ExamConsumable) {
    return this._httpClient.post<CommonResponse>(
      `${this.baseURL}UpdateExamconsumables`,
      request
    );
  }
  removeExamconsumables(request: ExamConsumable) {
    return this._httpClient.post<GeneralResModel<boolean>>(
      `${this.baseURL}RemoveExamconsumables`,
      request
    );
  }

  getAllExam(orgId: number) {
    return this._httpClient.get<GeneralResModel<RisExamDto[]>>(
      `${this.baseURL}GetAllExam/${orgId}`
    );
  }
  getAllExamList(orgId: number) {
    return this._httpClient.get<GeneralResModel<RisExamDto[]>>(
      `${this.baseURL}GetAllExamList/${orgId}`
    );
  }

  GetNextRank(orgId: number) {
    return this._httpClient.get<any>(`${this.baseURL}GetNextRank/${orgId}`);
  }

  getAllExamByPanelFilter(orgId: number, isPanel: boolean) {
    return this._httpClient.get<GeneralResModel<RisExamDto[]>>(
      `${this.baseURL}GetAllExamByPanelFilter/${orgId}/${isPanel}`
    );
  }

  GetAllExamByAnalyzer(orgId: number, analyzerId: number) {
    return this._httpClient.get<GeneralResModel<RisExamDto[]>>(
      `${this.baseURL}GetAllExamByAnalyzer/${orgId}/${analyzerId}`
    );
  }
  addExamInformation(request: ExamDtoModel) {
    return this._httpClient.post<GeneralResModel<ExamDtoModel>>(
      `${this.baseURL}AddExamInformation`,
      request
    );
  }

  updateExamInformation(request: ExamDtoModel) {
    return this._httpClient.post<GeneralResModel<ExamDtoModel>>(
      `${this.baseURL}UpdateExamInformation`,
      request
    );
  }

  // Exam Wise Exam Panel API's START
  getExamById(examId: number) {
    return this._httpClient.get<GeneralResModel<RisExamDto>>(
      `${this.baseURL}GetExamById/${examId}`
    );
  }

  getExamPanelByExamId(examId: number) {
    return this._httpClient.get<GeneralResModel<RisExamDto>>(
      `${this.baseURL}GetExamPanelByExamId/${examId}`
    );
  }

  getOrderExamPanel(orderId: number, examId: number) {
    return this._httpClient.get<GeneralResModel<RisExamDto>>(
      `${this.baseURL}GetOrderExamPanel/${orderId}/${examId}`
    );
  }

  getExamPanelDtlByPanelId(panelId: number) {
    return this._httpClient.get<GeneralResModel<RisExamPanelDtlModel[]>>(
      `${this.baseURL}GetExamPanelDtlByPanelId/${panelId}`
    );
  }

  addLISOrder(request: ExamOrderRequestModel) {
    return this._httpClient.post<GeneralResModel<ExamOrderRequestModel>>(
      `${this.baseURL}AddLISOrder`,
      request
    );
  }

  removeLISOrder(orderId: number, examId: number) {
    return this._httpClient.get<GeneralResModel<boolean>>(
      `${this.baseURL}RemoveLISOrder/${orderId}/${examId}`
    );
  }

  addLISOrderExamPanelList(request: PatientInfoViewModel) {
    return this._httpClient.post<GeneralResModel<RisOrderModel>>(
      `${this.baseURL}AddLISOrderExamPanelList`,
      request
    );
  }

  // Exam Wise Exam Panel API's END

  // Exam Result Entry API' START
  getExamResultListById(orderId: number, examId: number, regId: number) {
    return this._httpClient.get<GeneralResModel<ExamResult[]>>(
      `${this.baseURL}GetExamResultListById/${orderId}/${examId}/${regId}`
    );
  }

  updateExamResult(request: ExamResult[]) {
    return this._httpClient.post<GeneralResModel<boolean>>(
      `${this.baseURL}UpdateExamResult`,
      request
    );
  }
  // Exam Result Entry API' END

  // start Exam Reference value API
  getExamreferencevalueList(orgId?: number) {
    return this._httpClient.get<GeneralResModel<ExamReferencevalue[]>>(
      `${this.baseURL}GetExamreferencevalueList/${orgId}`
    );
  }

  getExamreferencevalueById(referenceValueId: number) {
    return this._httpClient.get<CommonResponse>(
      `${this.baseURL}GetExamreferencevalueById/${referenceValueId}`
    );
  }

  addExamreferencevalue(body: ExamReferencevalue) {
    return this._httpClient.post<CommonResponse>(
      `${this.baseURL}AddExamreferencevalue`,
      body
    );
  }

  removeExamreferencevalue(body: ExamReferencevalue) {
    return this._httpClient.post<CommonResponse>(
      `${this.baseURL}RemoveExamreferencevalue`,
      body
    );
  }

  updateExamreferencevalue(body: ExamReferencevalue) {
    return this._httpClient.post<CommonResponse>(
      `${this.baseURL}UpdateExamreferencevalue`,
      body
    );
  }
  //End Exam Reference value API

  // start Exam Panel  API
  getExampanelByOrgId(orgId?: number) {
    return this._httpClient.get<GeneralResModel<ExamPanel[]>>(
      `${this.baseURL}GetExampanelByOrgId/${orgId}`
    );
  }
  getExampanelListByExamId(orgId?: number) {
    return this._httpClient.get<GeneralResModel<ExamPanel[]>>(
      `${this.baseURL}GetExampanelListByExamId/${orgId}`
    );
  }
  getExampanelById(panelId: number) {
    return this._httpClient.get<CommonResponse>(
      `${this.baseURL}GetExampanelById/${panelId}`
    );
  }

  addExampanel(body: ExamPanel) {
    return this._httpClient.post<CommonResponse>(
      `${this.baseURL}AddExampanel`,
      body
    );
  }

  removeExampanel(body: ExamPanel) {
    return this._httpClient.post<CommonResponse>(
      `${this.baseURL}RemoveExampanel`,
      body
    );
  }
  removeAllChildExampanel(body: ExamPanel) {
    return this._httpClient.post<CommonResponse>(
      `${this.baseURL}RemoveAllChildExampanel`,
      body
    );
  }

  updateExampanel(body: ExamPanel) {
    return this._httpClient.post<CommonResponse>(
      `${this.baseURL}UpdateExampanel`,
      body
    );
  }
  //End Exam Panel API
}
